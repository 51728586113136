import logo from "../../../assets/images/logo-white.svg";
//import {AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignIn, AmplifySignUp} from "@aws-amplify/ui-react";
import React, {useState} from "react";
import './auth-index.css'
import {Auth} from "aws-amplify";
import {AuthState} from "@aws-amplify/ui-components";
import AuthComponents from "../auth-components/AuthComponents";

const AuthIndex = (props) => {
    const [validationCode, setValidationCode] = useState('');
    const [errorValidation, setErrorValidation] = useState(false);
    const [loading, setLoading] = useState(false)
    const handleCodeEnter = (e) => {
        setValidationCode(e.target.value);
    }

    const setSignedIn = () => {
        props.updateAuthState(AuthState.SignedIn);
    }

    const confirmSignUp = async () => {
        try {
            setLoading(true);
            await Auth.confirmSignUp(props.user, validationCode);
            await Auth.signIn(props.user, props.pass);
            setSignedIn();
            setLoading(false);
        } catch (error) {
            console.log('error confirming signup', error);
            setErrorValidation(true);
            setLoading(false);
        }
    }

    return (<div className="auth-components-container">
        <div className="login-image"></div>
        <div className="login-container">
            <div className="logo-container">

                {(props.alertMessage !== undefined && props.alertMessage !== "") ? (
                        <div className="alert">{props.alertMessage}</div>) :
                    <img src={logo} className="logo" alt="Catalytics Logo"/>}
            </div>
            {(props.verifySignUp) ? (

                <div className="verify-login-container">
                    {(errorValidation) ? (<div className="alert">The code is not valid. Please try again</div>) : ''}
                    <h3>Please enter the code we sent to your email</h3>
                    <input type="text" name="code" onChange={handleCodeEnter}/>
                    {(!loading) ? (
                        <div className="button-container">
                            <button onClick={confirmSignUp}>Continue</button>
                        </div>) : (<div className="loader">Loading...</div>)}

                </div>
            ) : (
                <AuthComponents setSignedIn={setSignedIn} />
            )
            }
        </div>
    </div>);
}

export default AuthIndex;