import React, {useEffect, useState} from "react";
import './header.css';
import HelpIcon from "../../assets/icons/Help";
import NotificationIcon from "../../assets/icons/Notification";
import UserIcon from "../../assets/icons/User";
import {Auth} from "aws-amplify";

//import chevronDown from "../../assets/icons/chevron_down.svg";


const Header = () => {
    const [name, setName] = useState('');

    useEffect(() => {
        (async () => {
            let currentUserInfo = await Auth.currentUserInfo();
            /*if (currentUserInfo['attributes'] !== undefined && currentUserInfo["attributes"]["given_name"] !== undefined && currentUserInfo["attributes"]["family_name"] !== undefined) {
                setName(currentUserInfo["attributes"]["given_name"]+' '+currentUserInfo["attributes"]["family_name"]);
            }*/if (currentUserInfo['attributes'] !== undefined && currentUserInfo["attributes"]["given_name"] !== undefined) {
                setName(currentUserInfo["attributes"]["given_name"]);
            } else {
                setName('Guest')
            }
        })();
    }, []);

    return (
        <header>
            <div className="menu-section gap">
                <div className="icon-button">
                    <NotificationIcon/>
                </div>
                <div className="icon-button">
                    <a href="https://docs.catalytics.io/" target="_blank"><HelpIcon/></a>
                </div>
            </div>
            <div className="divider"/>
            <div className="menu-section">
                <UserIcon/>
                <div className="user-menu" style={{fontSize: 12, marginLeft: 8}}>
                    {name}
                    {/* <img src={chevronDown} alt="Show Profile Info" />*/}
                    <div className="user-menu-options-container">
                        {/* <div className="user-menu-option">Option 1</div>
            <div className="user-menu-option">Option 2</div>
            <div className="user-menu-option">Option 3</div> */}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
