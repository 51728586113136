import React, {
    useState,
    forwardRef,
    useRef,
    useImperativeHandle,
} from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const refInput = useRef(null);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value;
            },

            isCancelAfterEnd() {
                // our editor will reject any value greater than 1000
                return value < new Date();
            },
        };
    });

    function updateValue(date) {
        setValue(date);
    }

    return (
        <DatePicker
            ref={refInput}
            style={{ height: '100%', width: '100%' }}
            portalId="body"
            popperClassName="ag-custom-component-popup"
            selected={value}
            onChange={updateValue}
        />
    );
});
export default CustomDatePicker;
