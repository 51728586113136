import axios from "axios";
import {Auth} from "aws-amplify";
import {apiException} from "../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../data/Urls";

let advertiserResponse = {
    status: false,
    error: '',
    info: {}
};

const CreateAdvertiser = async () => {
    const currentUserInfo = await Auth.currentUserInfo();
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const request = {
        "firstName": currentUserInfo["attributes"]["given_name"],
        "lastName": currentUserInfo["attributes"]["family_name"],
        "companyName": currentUserInfo["attributes"]["custom:Company"],
        "emailAddress": currentUserInfo["attributes"]["email"]
    };
    await axios.post(getCatalyticsApiUrl()+'create-advertiser',
        {
            "body": JSON.stringify(request)
        },
        {
            headers: {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(async (responseCreate) => {
        if (responseCreate.data === undefined || responseCreate.data.body === undefined) {
            advertiserResponse.error = 'Empty response from POST create advertiser';
            apiException(advertiserResponse.error,{'email': currentUserInfo["attributes"]["email"]},request,responseCreate);
            return;
        }
        let responseBody = JSON.parse(responseCreate.data.body);
        if (responseBody['status'] === undefined || responseBody['status'] !== 'OK' || responseBody['id'] === undefined || responseBody['id'] === '') {
            advertiserResponse.error = 'Error response from POST create advertiser (false status or missing id)';
            apiException(advertiserResponse.error,{'email': currentUserInfo["attributes"]["email"]},request,responseCreate);
            return;
        }
        advertiserResponse.status = true;
        advertiserResponse.info = responseBody;
    }).catch(function (error) {
        advertiserResponse.error = error;
        apiException(advertiserResponse.error,{'email': currentUserInfo["attributes"]["email"]},request,{});
    });
    return advertiserResponse;
}

export default CreateAdvertiser;