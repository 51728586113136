export const defaultHeaders = [
    {
        field: 'campaign',
        headerName: 'Campaign',
        //enableSorting: true,
        sortable: true,
        //filterable: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        filterParams: {
            buttons: ['clear'],
        },
    },
    // {
    //     field: 'business_article',
    //     headerName: 'Business/Article',
    //     sortable: true,
    //     //filterable: true,
    //     filter: 'agTextColumnFilter',
    //     filterParams: {
    //         buttons: ['clear'],
    //     },
    // },
    // {
    //     field: 'target',
    //     headerName: 'Target',
    //     sortable: true,
    //     //filterable: true,
    //     filter: 'agTextColumnFilter',
    //     filterParams: {
    //         buttons: ['clear'],
    //     },
    // },
    {
        field: 'impressions',
        headerName: 'Impressions',
        sortable: true,
        //filterable: true,
        valueFormatter: (params) =>
            params.data.impressions.toLocaleString('en-US'),
        filter: 'agNumberColumnFilter',
        filterParams: {
            buttons: ['clear'],
        },
    },
    {
        field: 'clicks',
        headerName: 'Clicks',
        sortable: true,
        //filterable: true,
        valueFormatter: (params) =>
            params.data.clicks.toLocaleString('en-US'),
        filter: 'agNumberColumnFilter',
        filterParams: {
            buttons: ['clear'],
        },
    },
    {
        field: 'ctr',
        headerName: 'CTR',
        sortable: true,
        //filterable: true,
        valueFormatter: (params) => {
            //params.data.ctr.toLocaleString('en-US'),
            return `${parseFloat((params.data.ctr * 100).toFixed(2))}%`
        },
        filter: 'agNumberColumnFilter',
        filterParams: {
            buttons: ['clear'],
        },
    },
    {
        field: 'engaged_visits',
        headerName: 'Engaged Visits',
        sortable: true,
        //filterable: true,
        valueFormatter: (params) =>
            params.data.engaged_visits.toLocaleString('en-US'),
        filter: 'agNumberColumnFilter',
        filterParams: {
            buttons: ['clear'],
        },
    },
    {
        field: 'conversions',
        headerName: 'Conversions',
        sortable: true,
        //filterable: true,
        valueFormatter: (params) =>
            params.data.conversions.toLocaleString('en-US'),
        filter: 'agNumberColumnFilter',
        filterParams: {
            buttons: ['clear'],
        },
    },
    {
        field: 'spend',
        headerName: 'Spent',
        sortable: true,
        //filterable: true,
        valueFormatter: (params) => formatUSD(params.data.spend.toLocaleString('en-US')),
        filter: 'agNumberColumnFilter',
        filterParams: {
            buttons: ['clear'],
        },
    },
    {
        field: 'daily_budget',
        headerName: 'Daily Budget',
        sortable: true,
        editable: true,
        //filterable: true,
        cellRenderer: 'DailyBudget',
        valueFormatter: (params) => formatUSD(params.data.daily_budget),
        filter: 'agNumberColumnFilter',
        cellClass: 'daily_budget',
        filterParams: {
            buttons: ['clear'],
        },
    },
    // {
    //     field: 'flight_start',
    //     headerName: 'Flight Start',
    //     sortable: true,
    //     editable: false,
    //     //filterable: true,
    //     filter: 'agDateColumnFilter',
    //     cellRenderer: dateRenderer,
    //     cellEditorPopup: true,
    //     cellEditor: 'CustomDatePicker',
    //     filterParams: {
    //         buttons: ['clear'],
    //     },
    // },
    // {
    //     field: 'flight_end',
    //     headerName: 'Flight End',
    //     sortable: true,
    //     editable: false,
    //     //filterable: true,
    //     filter: 'agDateColumnFilter',
    //     cellRenderer: dateRenderer,
    //     cellEditorPopup: true,
    //     filterParams: {
    //         buttons: ['clear'],
    //     },
    // },
];

function dateRenderer(params) {
    return params.value
        ? params.value.toLocaleDateString('en-us')
        : new Date().toLocaleDateString('en-us');
}

// function isEditable(event) {
//     let node = event.node;
//     return node.data.flight_start > new Date();
// }

function formatUSD(number) {
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return formatter.format(number);
}
