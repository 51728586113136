import React from 'react';
import axios from "axios";
import {Auth} from "aws-amplify";
import {apiException} from "../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../data/Urls";

const GetLineItems = async (advertiserId) => {
    let lineItemsResponse = {
        status: false,
        error: '',
        info: {}
    }
    const currentUserInfo = await Auth.currentUserInfo();
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const request = {'advertiser_id': advertiserId};
    await axios.get(getCatalyticsApiUrl() + 'line-items/',
        {
            params: {
                advertiser_id: advertiserId
            },
            headers: {
                'Authorization': `Basic ${token}`
            }
        }).then(async (response) => {
        if (response['data'] === null) {
            lineItemsResponse.error = 'Empty response from GET line items';
            apiException(lineItemsResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, response);
            return;
        }
        let body = JSON.parse(response['data']['body']);
        if (body['line-items'] === undefined) {
            lineItemsResponse.error = 'Invalid response from GET line items';
            apiException(lineItemsResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, body);
            return;
        }
        lineItemsResponse.status = true;
        lineItemsResponse.info = body['line-items'];
        //lineItemsResponse = await CreateInsertionOrders(advertiserId, name);
    }).catch(function (error) {
        lineItemsResponse.error = error;
        apiException(lineItemsResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, {});
    });
    return lineItemsResponse;
}


export default GetLineItems;