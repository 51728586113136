import React from "react";

function Select() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0V13.2917H4.58345V4.58327H13.2917V0H0Z" fill="#131B23" />
      <path
        d="M4.58345 39.4171V30.7085H0V44H13.2917V39.4171H4.58345Z"
        fill="#131B23"
      />
      <path
        d="M39.4164 30.7085V39.4171H30.708V44H43.9996V30.7085H39.4164Z"
        fill="#131B23"
      />
      <path
        d="M30.708 0V4.58327H39.4164V13.2917H43.9996V0H30.708Z"
        fill="#131B23"
      />
    </svg>
  );
}

export default Select;
