import React from "react";

function Mail() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.59998V37.4H44V6.59998H0ZM3.3 16.775L11 22.55L3.3 27.555V16.775ZM40.7 34.1H3.3V31.4908L13.8114 24.6585L22 30.8L30.1886 24.6585L40.7 31.4908V34.1ZM40.7 27.555L33 22.55L40.7 16.775V27.555ZM40.7 12.65L22 26.675L3.3 12.65V9.89998H40.7V12.65Z"
        fill="#131B23"
      />
    </svg>
  );
}

export default Mail;
