import React, {useState} from 'react';
import axios from "axios";
import {Auth} from "aws-amplify";
import {apiException} from "../../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../../data/Urls";

let paymentIntentResponse = {
    status: false,
    error: '',
    info: {}
};

const CreatePaymentIntent = async (amount) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const currentUserInfo = await Auth.currentUserInfo();
    await axios.post(getCatalyticsApiUrl()+'stripe-payment-intent', {
            params: {
                amount: amount,
                "env": (process.env.REACT_APP_BUILD_ENV === 'development')?'development':''
            },
            headers: {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'application/json'
            }
        }
    ).then(async (response) => {
        if (response.data === undefined || response.data.body === undefined) {
            paymentIntentResponse.error = 'Empty response from POST payment intent';
            apiException(paymentIntentResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},response);
        }
        let body = JSON.parse(response['data']['body']);
        if (body['paymentIntent'] === undefined || body['paymentIntent']['client_secret'] === undefined) {
            paymentIntentResponse.error = 'Payment Intent info not returned from POST payment intent';
            apiException(paymentIntentResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},response);
        }
        let clientSecret = body['paymentIntent']['client_secret'];
        paymentIntentResponse.status = true;
        paymentIntentResponse.info = clientSecret;
    }).catch(function (error) {
        paymentIntentResponse.error = error;
        apiException(paymentIntentResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},{});
    });
    return paymentIntentResponse;
}

export default CreatePaymentIntent;