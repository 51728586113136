import React from 'react';
import axios from "axios";
import {Auth} from "aws-amplify";
import {apiException} from "../../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../../data/Urls";

let subscriptionResponse = {
    status: false,
    error: '',
    info: {}
};

const UpdateSubscriptionPrice = async (subscriptionId, price) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const currentUserInfo = await Auth.currentUserInfo();
    await axios.put(getCatalyticsApiUrl() + 'stripe-subscription', {
            "subscriptionId": subscriptionId,
            "price":price,
            "env": (process.env.REACT_APP_BUILD_ENV === 'development') ? 'development' : ''
        },
        {
            headers: {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'application/json'
            }
        }
    ).then(async (response) => {
        if (response.data === undefined || response.data.body === undefined) {
            subscriptionResponse.error = 'Empty response from PUT subscription';
            apiException(subscriptionResponse.error, {'email': currentUserInfo["attributes"]["email"]}, {}, response);
            return;
        }
        let body = JSON.parse(response['data']['body']);
        if (body['subscriptionId'] === undefined) {
            subscriptionResponse.error = 'Subscription id not returned from PUT subscription';
            apiException(subscriptionResponse.error, {'email': currentUserInfo["attributes"]["email"]}, {}, response);
            return;
        }
        subscriptionResponse.status = true;
        subscriptionResponse.info = body;
    }).catch(function (error) {
        subscriptionResponse.error = error;
        apiException(subscriptionResponse.error, {'email': currentUserInfo["attributes"]["email"]}, {}, {});
    });
    return subscriptionResponse;
}

export default UpdateSubscriptionPrice