/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://qaiks7nzhragzba46noqe33lmi.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-agqpds4atbcqnldyt42xztauwe",
    "aws_cognito_identity_pool_id": "us-east-2:5104e72f-d2b4-4034-b26a-5c8f1127ba03",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_gwNrWCsYl",
    "aws_user_pools_web_client_id": "29547cdgfn8dvmgm8ciervhml5",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "catalytics9e8ad7ef02d541bc988fbae4a473637b165144-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
