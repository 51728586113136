import axios from "axios";
import {Auth} from "aws-amplify";
import {apiException} from "../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../data/Urls";

let insertionOrderResponse = {
    status: false,
    error: '',
    info: {}
};

const cleanString = (input) => {
    return input.replace(/[^A-Z0-9]/ig, "_");
}

const CreateInsertionOrder = async (advertiserId, advertiserName, budget, startDate, endDate, code, billing_code) => {
    //budget = 0;//TODO: to distinguish when going live based on env vars
    const currentUserInfo = await Auth.currentUserInfo();
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const request = {
        "advertiserId": advertiserId.toString(),
        "advertiserName": advertiserName,
        "budget": budget,
        "startDate": startDate,
        "endDate": endDate,
        "code": code,
        "billing_code": billing_code
    };
    await axios.post(getCatalyticsApiUrl()+'create-insertion-order',
        {
            "body": JSON.stringify(request)
        },
        {
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': `Basic ${token}`
            }
        }).then(async (responseCreate) => {
        if (responseCreate.data === undefined || responseCreate.data.body === undefined) {
            insertionOrderResponse.error = 'Empty response from POST insertion order service';
            apiException(insertionOrderResponse.error,{'email': currentUserInfo["attributes"]["email"]},request,responseCreate);
            return;
        }

        let bodyCreate = JSON.parse(responseCreate['data']['body']);
        if (bodyCreate['status'] === undefined || bodyCreate['status'] !== 'OK' || bodyCreate['id'] === undefined && bodyCreate['id'] === '') {
            insertionOrderResponse.error = 'Invalid response from POST insertion order service';
            apiException(insertionOrderResponse.error,{'email': currentUserInfo["attributes"]["email"]},request,responseCreate);
            return;
        }
        insertionOrderResponse.status = true;
        insertionOrderResponse.info = bodyCreate;
    }).catch(function (error) {
        insertionOrderResponse.error = error;
        apiException(insertionOrderResponse.error,{'email': currentUserInfo["attributes"]["email"]},request,{});
    });
    return insertionOrderResponse;
}

export default CreateInsertionOrder;