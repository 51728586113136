import React, {useContext, useState} from "react";
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
//import stripeProducts from '../../../../data/StripeProducts';
//import {AdvertiserContext} from '../../../AdvertiserContext'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
//import {DateRange} from 'react-date-range';
import StepsBar from "../steps-bar/StepsBar";
//import {addDays} from 'date-fns';
import Calendar from 'react-calendar';
import Tabs from "../../../util/tabs/Tabs";
import CampaignItemsTable from "../../../util/campaign-items-table/CampaignItemsTable";
import {CampaignContext} from "../../../../context/CampaignContext";
import './campaign-settings.css';
import {Link} from "react-router-dom";
import Radiobox from "../../../util/radio-box/Radiobox";
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const CampaignSettings = (props) => {

    //const { orgName, address, landingPage, conversionEvent, selection1, selection2, selection3, updateAdvertiser, updateFlights } = useContext(AdvertiserContext);
    const {
        budgetType,
        budget,
        assignedBudget,
        conversionTracking,
        updateCampaign,
        startDate,
        endDate,
        isContinuous,
        countBusinessTargets,
        countArticleTargets,
        setResetBudget,
        setResetDates,
        campaignIsReady,
        assignBudgetToTargets,
        remainingBudget,
        calculateAssignedBudget,
        budgetId,
        setRemainingBudget
    } = useContext(CampaignContext);
    const [calendarDates, setCalendarDates] = useState(() => {

        if (isContinuous) {
            return [startDate, startDate];

        }
        return [startDate, endDate];
    });
    const [budgetAlert, setBudgetAlert] = useState(false);
    const [budgetValidationAlert, setBudgetValidationAlert] = useState(() => {
        if (typeof budgetId === typeof undefined) {
            return true;
        }
        return false;
    })
    const [defaultInfoTracking1, setDefaultInfoTracking1] = useState(false);
    const [defaultInfoTracking2, setDefaultInfoTracking2] = useState(false);

    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    //var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    const monthlyBudgetLimit = 200;

    /*<form>
                <label htmlFor="campaign-name" style={{display: "block"}}>Campaign Landing Page</label>
                <input id="landingPage" name="landingPage" placeholder="Landing Page URL" type="text"
                       value={landingPage} onChange={handleChange}/>

                <label htmlFor="campaign-name" style={{display: "block"}}>Conversion Event</label>
                <input id="conversionEvent" name="conversionEvent" placeholder="Goal URL, Button-Click, ..." type="text"
                       value={conversionEvent} onChange={handleChange}/>


                <br/>
            </form>*/

    const setCampaignDates = (dates) => {
        if (isContinuous) {
            updateCampaign({
                startDate: dates
            });
            setCalendarDates([dates, dates]);
        } else {
            updateCampaign({
                startDate: dates[0],
                endDate: dates[1]
            });
            setCalendarDates([dates[0], dates[1]]);
        }
        setResetDates(1);
    }


    const setContinuous = (e) => {
        let continuous = e.target.checked;
        if (continuous) {

            updateCampaign({
                startDate: startDate,
                isContinuous: true,
                budgetType: 'day'
            });
            setCalendarDates([startDate, startDate]);
            return;
        }


        updateCampaign({
            startDate: startDate,
            endDate: startDate,
            isContinuous: false
        });
        setCalendarDates([startDate, startDate]);
        //setCalendarDates([startDate]);
        setResetDates(1);
    }

    function getDifferenceInDays() {
        return Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)+1);
    }

    const checkBudget = (enteredBudget) => {
        if (budget === undefined) {
            return;
        }
        enteredBudget = enteredBudget.replace(',', '');
        if (isNaN(enteredBudget)) {
            setBudgetAlert('Please set a valid budget');
            return;
        }
        var foundAlert=false;
        switch (budgetType) {
            case 'day':
                if (isContinuous) {
                    if ((enteredBudget * 30) < monthlyBudgetLimit) {
                        setBudgetAlert('The minimal budget is $200 per month');
                        foundAlert=true;
                    }
                } else {
                    if (enteredBudget * getDifferenceInDays() < monthlyBudgetLimit) {
                        setBudgetAlert('The minimal budget is $200 per month');
                        foundAlert=true;
                    }
                }
                break;
            case 'flight':
                if (enteredBudget < monthlyBudgetLimit) {
                    setBudgetAlert('The minimal budget is $200 per month');
                    foundAlert=true;
                }
                break;
        }
        if (!foundAlert){
            setBudgetAlert('');
        }
        setResetBudget(1);
        updateCampaign({budget: enteredBudget});
        assignBudgetToTargets(enteredBudget);
        calculateAssignedBudget(null, null, enteredBudget);
        //setRemainingBudget(0);
    }

    const defaultMaskOptions = createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ',',
        allowDecimal: true,
        decimalSymbol: '.',
        decimalLimit: 2, // how many digits allowed after the decimal
        integerLimit: 7, // limit length of integer numbers
        allowNegative: false,
        allowLeadingZeroes: false,
    });

    /*var moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });*/

    /*const budgetSelectionHandler = (value) => {
        if (value !== "9999" && !isNaN(value)) {
            const thisBudgetSelected = stripeProducts()[value];
            console.log(thisBudgetSelected);
            updateCampaign({budgetId: value});
            checkBudget(thisBudgetSelected['price']);
            assignBudgetToTargets(thisBudgetSelected['price']);
            setBudgetValidationAlert(false);
            return;
        }
        setBudgetValidationAlert(true);
        updateCampaign({budget: undefined});
        checkBudget(0);

    }*/

    return (
        <div className="campaign-settings">
            <ReactTooltip backgroundColor="#f3f3f3" textColor="#000000" multiline={true}/>
            <div className="campaign-settings-top">
                <div className='step-description'>
                    <h2>Budget &amp; Start</h2>
                </div>
                <StepsBar step="3"/>
                <div className="form-columns">
                    <div className="form-column">
                        <div className="section-title">Set a daily budget for your campaigns</div>
                        <label className="section-label">I want to spend</label>
                        <div className="amount-input">
                            <span className="amount-currency-symbol">$</span>
                            <MaskedInput
                                mask={defaultMaskOptions}
                                defaultValue={budget}
                                onChange={(e) => {
                                    checkBudget(e.target.value)
                                }}
                                /*onBlur={(e) => {
                                    assignBudgetToTargets(e.target.value);
                                }}*/
                                placeholder="Enter Budget, e.g. $1,000"
                            />
                            {(budgetAlert !== '') ? (<div className="budget-alert">{budgetAlert}</div>) : ''}
                            {/*<select name="budget" value={budgetId} className={(budgetValidationAlert) ? 'alert' : ''}
                                    onChange={e => budgetSelectionHandler(e.target.value)}
                                    placeholder="Please select a budget">
                                <option value="9999" disabled hidden>Please select a budget</option>
                                {stripeProducts().map((product, key) => {
                                    return <option key={key} value={key}>{moneyFormatter.format(product.price)}</option>
                                })}
                            </select>*/}
                        </div>
                        <div className="per-select-container">
                            {(!isContinuous) ? (<label>Per</label>) : (<label>Per day</label>)}
                            {(!isContinuous) ? (<div className="per-selection">
                                <a href="#!"
                                   className={(budgetType === undefined || budgetType === 'day') ? 'selected' : ''}
                                   onClick={() => {
                                       updateCampaign({budgetType: 'day'});
                                   }}>Day</a>
                                <a href="#!" className={(budgetType === 'flight') ? 'selected' : ''} onClick={() => {
                                    updateCampaign({budgetType: 'flight'});
                                }}>Flight</a>
                            </div>) : ''}
                        </div>
                        {/* <div className="conversion-tracking">
                            <div className="ct-title">Conversion Tracking</div>
                            <label className="ct-label">Choose your conversion tracking option. Need help? Contact
                                us</label>
                            <div className="radio-container">
                                <Radiobox
                                    checked={conversionTracking === "default"}
                                    name="conversion-tracking"
                                    onChange={() => updateCampaign({conversionTracking: "default"})}
                                    // label="Default Tracking"
                                />
                                Default Tracking
                                <a href="#!" onClick={(e) => {
                                    e.preventDefault();
                                    setDefaultInfoTracking1(!defaultInfoTracking1)
                                }}>
                                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8 8.00013L0 0.000126543L3.99991 0.000126717L8 4.00013L11.9999 -0.000121546L16 0.000127242L8 8.00013Z"
                                            fill="black"/>
                                    </svg>
                                </a>
                            </div>
                            {(defaultInfoTracking1) ? (
                                <div className="tracking-explanation">Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt mollit anim id est
                                    laborum.</div>) : ''}
                            <div className="radio-container">
                                <Radiobox
                                    checked={conversionTracking === "gtm"}
                                    value="gtm" className="radio"
                                    name="conversion-tracking"
                                    onChange={() => updateCampaign({conversionTracking: "gtm"})}
                                    // label="Google Tag Manager"
                                />
                                Google Tag Manager
                                <a href="#!" onClick={(e) => {
                                    e.preventDefault();
                                    setDefaultInfoTracking2(!defaultInfoTracking2)
                                }}>
                                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8 8.00013L0 0.000126543L3.99991 0.000126717L8 4.00013L11.9999 -0.000121546L16 0.000127242L8 8.00013Z"
                                            fill="black"/>
                                    </svg>
                                </a>
                            </div>
                            {(defaultInfoTracking2) ? (
                                <div className="tracking-explanation">Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt mollit anim id est
                                    laborum.</div>) : ''}
                        </div> */}
                    </div>
                    <div className="form-column">
                        <div className="section-title">Select a start date:</div>
                        {/*<div className="section-description">Select Flight Dates and uncheck "Run Campaign
                            Continuously". Or just select start date and
                            check "Run Campaign Continuously"
                        </div>*/}
                        <div className="calendar-container">
                            <div className="calendar">
                                <Calendar
                                    onChange={setCampaignDates}
                                    value={calendarDates}
                                    defaultValue={calendarDates}
                                    calendarType="US"
                                    locale="en-US"
                                    //showNeighboringMonth={false}
                                    minDate={moment().add(1, 'days').toDate()}
                                    formatShortWeekday={(locale, date) => days[date.getDay()].substring(0, 2)}
                                    selectRange={(!isContinuous)}
                                />
                            </div>
                            <div className="selected-date">
                                <div className="date-label">Start</div>
                                <div className="day-month">
                                    {days[startDate.getDay()]},
                                    <br/>
                                    {moment(startDate).format("MM.DD.YYYY")}
                                </div>
                                {(!isContinuous) && (
                                    <div>
                                        <div className="date-label end">End</div>
                                        <div className="day-month">
                                            {days[endDate.getDay()]},
                                            <br/>
                                            {moment(endDate).format("MM.DD.YYYY")}
                                        </div>
                                    </div>
                                )}
                                <div className="continuous-campaign-container">
                                    <label className={`checkbox-container ${!isContinuous ? "end" : ""}`}>
                                        Run Campaign Continuously
                                        <a href="#!" className="tooltip"
                                           data-tip='Select "Run Campaign Continuously"<br />
                                           if you would like to run your<br /> campaign indefinitely'>
                                            <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4 0C1.79439 0 0 1.79439 0 4C0 6.20561 1.79435 8 3.99997 8C6.20558 8 7.99997 6.20561 7.99997 4C7.99997 1.79439 6.20558 0 4 0ZM4 7.02174C2.3338 7.02174 0.978258 5.6662 0.978258 4C0.978258 2.3338 2.3338 0.978258 4 0.978258C5.6662 0.978258 7.02174 2.3338 7.02174 4C7.02174 5.6662 5.66617 7.02174 4 7.02174Z"
                                                    fill="white"/>
                                                <path d="M4.489 3.55981H3.51074V6.13589H4.489V3.55981Z" fill="white"/>
                                                <path d="M4.489 1.86414H3.51074V2.80979H4.489V1.86414Z" fill="white"/>
                                            </svg>
                                        </a>
                                        <input
                                            type="checkbox"
                                            name="continuous-campaign"
                                            onChange={(e) => {
                                                setContinuous(e)
                                            }}
                                            defaultChecked={isContinuous}
                                        />
                                        <span className="checkmark">✔</span>
                                    </label>

                                </div>
                            </div>
                        </div>
                        {/* <div className="calendar-note">Please notice your campaign will not begin and budget will not be
                            used until your ads and landing pages have been approved and verified.
                        </div> */}
                    </div>

                </div>
            </div>
            <div className="campaign-settings-bottom">
                <div className="campaign-settings-bottom-container">
                    <div className="top-sections">
                        <div className="title-section">
                            <h4>Set a budget and start date for each media target</h4>
                            <p>The daily budget set above is distributed equally between media targets by default, but the distribution can be edited below. Uploaded ads can be assigned to media targets individually or add all ads to all targets using the ellipsis to the right of the "Assign Uploaded Ad(s)" column header.</p>
                        </div>
                        <div className="budget-section">
                            <h4>Remaining Budget for Allocation:</h4>
                            <span>
                                {(remainingBudget >= 0) ? <span>${remainingBudget.toFixed(2)}</span> :
                                    <span className="alert-budget">-${Math.abs(remainingBudget).toFixed(2)}</span>
                                }
                            </span>
                        </div>
                    </div>

                    <div className="campaign-list-items-container">
                        <Tabs
                            defaultSelected={(countArticleTargets > countBusinessTargets) ? 1 : 0}
                            tabOptions={['Businesses (' + countBusinessTargets + ')', 'Articles (' + countArticleTargets + ')']}
                            tabContents={[
                                (<CampaignItemsTable key={1} tableType="business-search"/>),
                                (<CampaignItemsTable key={2} tableType="keyword-search"/>)
                            ]}
                        />
                    </div>

                </div>
                <div className="step-buttons-container">
                    <div className="step-button-back">
                        <Link className="button secondary"
                              to="/create-campaign/1">Previous</Link>
                    </div>
                    {(campaignIsReady) ? (
                        <div className="step-button-next">
                            <Link className="button"
                                  to="/create-campaign/3">Next</Link>
                        </div>
                    ) : ''}
                </div>
            </div>
        </div>

    )

}
export default CampaignSettings