import React, {useState, useEffect} from "react";
import "./tabs.css";

const Tabs = (props) => {
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        if (props.defaultSelected !== undefined) {
            setSelectedTab(parseInt(props.defaultSelected));
        }
    }, [props.defaultSelected])

    const selectedTabHandler = (index) => {
        setSelectedTab(index);
    }

    return (
        <div className="tabs-container">
            <div className="tabs">
                {
                    props.tabOptions.map((tabOption, index) => {
                        return (<div key={index} className={(selectedTab === index) ? 'selected' : ''}
                                     onClick={() => selectedTabHandler(index)}>
                            {tabOption}
                        </div>)
                    })
                }
            </div>
            {
                props.tabContents.map((tabContent, index) => {
                    return (<div key={index}
                                 className={(selectedTab === index) ? 'tab-content selected' : 'tab-content'}>
                        {tabContent}
                    </div>)
                })
            }


        </div>

    )
}

export default Tabs;