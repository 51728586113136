import React, {useRef} from "react";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import MultiSelectOption from "./multi-select-option/MultiSelectOption";

const MultiSelect = (props) => {
    const valueRef = useRef(props.value);
    valueRef.current = props.value;

    const animatedComponents = makeAnimated();

    const selectAllOption = {
        value: "<SELECT_ALL>",
        label: "All Items"
    };

    const isSelectAllSelected = () =>
        valueRef.current.length === props.options.length;

    const isOptionSelected = option =>
        valueRef.current.some(({value}) => value === option.value) ||
        isSelectAllSelected();

    const getOptions = () => [selectAllOption, ...props.options];

    const getValue = () =>
        isSelectAllSelected() ? [selectAllOption] : props.value;

    const onChange = (newValue, actionMeta) => {
        const {action, option, removedValue} = actionMeta;

        if (action === "select-option" && option.value === selectAllOption.value) {
            props.onChange(props.options, actionMeta);
        } else if (
            (action === "deselect-option" &&
                option.value === selectAllOption.value) ||
            (action === "remove-value" &&
                removedValue.value === selectAllOption.value)
        ) {
            props.onChange([], actionMeta);
        } else if (
            actionMeta.action === "deselect-option" &&
            isSelectAllSelected()
        ) {
            props.onChange(
                props.options.filter(({value}) => value !== option.value),
                actionMeta
            );
        } else {
            props.onChange(newValue || [], actionMeta);
        }
    };

    return (
        <Select
            classNamePrefix="multi-select"
            placeholder={props.placeholder}
            isOptionSelected={isOptionSelected}
            options={getOptions()}
            value={getValue()}
            onChange={onChange}
            components={{
                Option: MultiSelectOption,
                animatedComponents
            }}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            isMulti
        />
    );
}

export default MultiSelect;