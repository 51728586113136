const StripeProducts = () => {
    if (process.env.REACT_APP_BUILD_ENV === 'development') {
        return [
            {name: 'Plan 1', price: 200.00, priceId: 'price_1KRNUVH9Krw0xqVKSiaTRPXL'},
            {name: 'Plan 2', price: 500.00, priceId: 'price_1KRNeYH9Krw0xqVKNsTfouyE'},
            {name: 'Plan 3', price: 1000.00, priceId: 'price_1KRNfmH9Krw0xqVKYOOXPTzN'},
            {name: 'Plan 4', price: 3000.00, priceId: 'price_1KRNhiH9Krw0xqVKdo0arW8Z'},
            {name: 'Plan 5', price: 5000.00, priceId: 'price_1KRNiBH9Krw0xqVK6Fv5uSwo'},
            {name: 'Plan 6', price: 10000.00, priceId: 'price_1KRNj6H9Krw0xqVKMT1E58mh'},
        ]
    }

    return [
        {name: 'Plan 1', price: 200.00, priceId: 'price_1Kj8eYH9Krw0xqVK48aZSXGt'},
        {name: 'Plan 2', price: 500.00, priceId: 'price_1Kj8eRH9Krw0xqVKxSc1DSQB'},
        {name: 'Plan 3', price: 1000.00, priceId: 'price_1Kj8eLH9Krw0xqVKtfR1Cn7N'},
        {name: 'Plan 4', price: 3000.00, priceId: 'price_1Kj8e8H9Krw0xqVK2TrTpcsw'},
        {name: 'Plan 5', price: 5000.00, priceId: 'price_1Kj8e2H9Krw0xqVKykWZCyF1'},
        {name: 'Plan 6', price: 10000.00, priceId: 'price_1Kj8cMH9Krw0xqVK6y4NrOT7'},
    ];
}


export default StripeProducts

