import React from 'react';
import axios from "axios";
import {Auth} from "aws-amplify";
import {apiException} from "../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../data/Urls";

const GetReport = async (advertiserId) => {
    let reportResponse = {
        status: false,
        error: '',
        info: {}
    }
    const currentUserInfo = await Auth.currentUserInfo();
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const request = {
        start_date: firstDay.toLocaleDateString('en-CA'),
        end_date: lastDay.toLocaleDateString('en-CA'),
        report_type: 'network_analytics',
        advertiser_id: advertiserId
    };
    await axios.get(getCatalyticsApiUrl() + 'reports',
        {
            params: {
                start_date: firstDay.toLocaleDateString('en-CA'),
                end_date: lastDay.toLocaleDateString('en-CA'),
                report_type: 'network_analytics',
                advertiser_id: advertiserId
            },
            headers: {
                'Authorization': `Basic ${token}`
            }
        }).then(async (response) => {
        if (response['data'] === null) {
            reportResponse.error = 'Empty response from GET report';
            apiException(reportResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, response);
            return;
        }
        let body = JSON.parse(response['data']['body']);
        if (body['report_data'] === undefined) {
            reportResponse.error = 'Invalid response from GET report';
            apiException(reportResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, body);
            return;
        }
        reportResponse.status = true;
        reportResponse.info = body['report_data'];

    }).catch(function (error) {
        reportResponse.error = error;
        apiException(reportResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, {});
    });
    return reportResponse;
}


export default GetReport;