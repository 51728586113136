import React, {useEffect, useRef, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {getRowStyle} from './shared/GridControls';
import AuthorizeButton from './shared/AuthorizeButton';
import './summary-grid.css';

const SummaryGrid = ({
    editedCampaigns,
    upcomingFlights,
    lineItems,
    reportData,
    updateTableData,
    insertionOrders,
    activeSubscriptionAmount,
    activeSubscriptionPrice
    }) => {
    const summaryGridRef = useRef();
    const [upcoming, setUpcoming] = useState();
    const [edited, setEdited] = useState();

    useEffect(() => {
        setUpcoming(formatUpcoming());
        setEdited(formatEdited());
    }, [editedCampaigns, upcomingFlights]);

    function formatUpcoming() {
        // lineitem.state = 'active' or 'inactive'
        if (upcomingFlights.length > 0) {
            return upcomingFlights
                .filter((u) => !editedCampaigns[u.campaign])
                .reduce((p, c) => {
                    let endDate = new Date(c.flight_end);
                    endDate = endDate.setDate(endDate.getDate() + 1);
                    let data = {
                        campaign: c.campaign,
                        upcoming_flights: formatFlightDates(c),
                        campaign_type: 'Auto Renew',
                        daily_budget: c.daily_budget,
                        total_budget: c.total_budget,
                        paid: c.total_budget,
                        action: `Auto-Pay Pending - ${new Date(
                            endDate
                        ).toLocaleDateString('en-us')}`,
                    };
                    return [...p, data];
                }, []);
        } else {
            return [];
        }
    }

    function formatEdited() {
        return Object.keys(editedCampaigns).reduce((p, c) => {
            let newData = editedCampaigns[c].newValue;
            let originalData = editedCampaigns[c].originalValue;
            let data = {
                campaign: c,
                upcoming_flights: formatFlightDates(newData),
                campaign_type: 'Approve Monthly',
                daily_budget: newData.daily_budget ?? originalData.daily_budget,
                total_budget: newData.daily_budget
                    ? newData.daily_budget * 7
                    : originalData.total_budget,
                paid: originalData.total_budget,
                action: 'approve',
            };
            return [...p, data];
        }, []);
    }

    function formatFlightDates(c) {
        return `${c.flight_start.toLocaleDateString(
            'en-us'
        )}-${c.flight_end.toLocaleDateString('en-us')}`;
    }

    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'upcoming_flights',
            headerName: 'FLIGHT',
        },
        /*{
            field: 'campaign_type',
            headerName: 'CAMPAIGN TYPE',
        },*/
        {
            field: 'daily_budget',
            headerName: 'SUM OF DAILY BUDGETS',
        },
        {
            field: 'total_budget',
            headerName: 'SUBSCRIPTION',
        },
        /* {
             field: 'paid',
             headerName: 'PAID',
         },*/
        {
            field: 'action',
            headerName: 'UPDATE SUBSCRIPTION',
            minWidth: 175,
            cellRenderer: 'AuthorizeButton',
            cellRendererParams: {
                lineItems: lineItems,
                reportData: reportData,
                updateTable: updateTableData,
            },
        },
    ]);

    return (
        <div className="summary-container">
            <div className="text-box">
                <span style={{fontSize: 14}}>SUMMARY</span>
                <span style={{fontSize: 12, marginTop: 32}}>
                    View upcoming flights, current flight status, and authorize
                    billing.
                </span>
            </div>
            <div style={{height: 150, width: '60vw'}}>
                {/*<div
                    className="ag-theme-alpine"
                    style={{ height: '100%', width: '100%' }}
                >
                    {edited && upcoming ? (
                        <AgGridReact
                            ref={summaryGridRef}
                            getRowStyle={getRowStyle}
                            rowData={[...edited, ...upcoming]}
                            columnDefs={columnDefs}
                            onGridReady={() =>
                                summaryGridRef.current.api.sizeColumnsToFit()
                            }
                            frameworkComponents={{ AuthorizeButton }}
                        />
                    ) : null}
                </div>*/}
                <table className="default-table">
                    <thead>
                        <tr>
                            <th>FLIGHT</th>
                            <th>SUM OF DAILY BUDGETS</th>
                            <th>SUBSCRIPTION</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {insertionOrders.map((insertionOrder, index) => {
                        let sumOfBudgets = insertionOrder['budget_intervals'][0]['lifetime_budget'];
                        let startDatePieces = insertionOrder['budget_intervals'][0]['start_date'].split(' ');
                        let endDatePieces = insertionOrder['budget_intervals'][0]['end_date'].split(' ');
                        // let endDateYearMonthDay=endDatePieces[0].split('-');
                        // console.log('endDateYearMonthDay: '+endDateYearMonthDay)
                        // let endDateObj = new Date(endDateYearMonthDay[2],parseInt(endDateYearMonthDay[1])-1,parseInt(endDateYearMonthDay[2]));
                        // console.log('endDateObj: '+endDateObj)
                        //console.log('variables assigned')
                        // if (endDateObj<new Date()){
                        //     console.log('new date: '+new Date())
                        //     return;
                        // }
                        return (<tr key={index}>
                            <td>{startDatePieces[0]} / {endDatePieces[0]}</td>
                            <td>${sumOfBudgets}</td>
                            <td>${activeSubscriptionPrice}</td>
                            <td className="no-padding"><a href="/account-dashboard" className="button">Update Subscription</a></td>
                        </tr>);

                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SummaryGrid;

