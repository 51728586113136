import React, {useLayoutEffect} from "react";
//import AdvertiserContextProvider from "../AdvertiserContext";
//import {Elements} from "@stripe/react-stripe-js";
import Header from "../header/Header";
import SideNav from "../side-nav/SideNav";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import CreateCampaign from "../create-campaign/CreateCampaign";
import ControlRoom from "../control-room/ControlRoom";
import ControlRoomv2 from "../control-room/ControlRoomv2";
import AccountDashboard from "../account-dashboard/AccountDashboard";
import Tracking from "../tracking/Tracking";
import NotFound from "../not-found/NotFound";
//import {loadStripe} from "@stripe/stripe-js";
import './app-index.css';

//import {AccountContext} from "../../context/AccountContext";
//import {CampaignContext} from "../../context/CampaignContext";
//import {v4 as uuid} from "uuid";
import ScrollToTop from "../util/scroll-to-top/ScrollToTop";

//const stripePromise = loadStripe('pk_test_CzBmMYtI0nIQypgPgfSNiuDO00zXgE1LHM');

//<AmplifySignOut buttonText="Sign Out"/>
const AppIndex = (props) => {
    //const {updateAccount,accountId} = useContext(AccountContext);

    useLayoutEffect(() => {
        /*updateAccount({
            accountId: props.accountInfo['accountId'],
            accountEmail: props.accountInfo['accountEmail'],
            accountUser: props.accountInfo['accountUser'],
            accountType: props.accountInfo['accountType']
        });*/
    }, []);

    return (<main id="app">


        <BrowserRouter>
            <ScrollToTop/>
            <div className="main-container">
                <SideNav/>
                <div className="app-wrapper">
                    <Header/>
                    <div className="app-container">

                        <Routes>

                            <Route path="/" element={<Navigate replace to="/create-campaign/0"/>}/>
                            <Route path="/create-campaign" element={<Navigate replace to="/create-campaign/0"/>}/>
                            <Route path="/create-campaign/" element={<Navigate replace to="/create-campaign/0"/>}/>
                            <Route path="/start-trial/:code" element={<Navigate replace to="/create-campaign/0"/>}/>
                            <Route path="/create-campaign/:step" element={<CreateCampaign/>}/>
                            <Route path="/control-room" element={<ControlRoom/>}/>
                            <Route path="/control-room-v2" element={<ControlRoomv2/>}/>
                            <Route path="/account-dashboard" element={<AccountDashboard/>}/>
                            <Route path="/tracking" element={<Tracking/>} />
                            <Route path="*" element={<NotFound/>}/>
                        </Routes>

                    </div>
                </div>
            </div>

        </BrowserRouter>

    </main>)
}

export default AppIndex