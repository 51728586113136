import React, {useState} from 'react';
import axios from "axios";
import {Auth} from "aws-amplify";
import {apiException} from "../../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../../data/Urls";

let invoiceResponse = {
    status: false,
    error: '',
    info: {}
};

const GetInvoice = async (invoiceId) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const currentUserInfo = await Auth.currentUserInfo();
    await axios.get(getCatalyticsApiUrl()+'stripe-invoice', {
            params: {
                invoiceId: invoiceId,
                "env": (process.env.REACT_APP_BUILD_ENV === 'development')?'development':''
            },
            headers: {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'application/json'
            }
        }
    ).then(async (responseGet) => {
        if (responseGet.data === undefined || responseGet.data.body === undefined) {
            invoiceResponse.error = 'Empty response from GET invoice';
            apiException(invoiceResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},responseGet);
        }
        let body = JSON.parse(responseGet['data']['body']);
        if (body['invoiceInfo'] === undefined || body['invoiceInfo']['id'] === undefined) {
            invoiceResponse.error = 'Invoice info not returned from GET invoice';
            apiException(invoiceResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},responseGet);
        }
        invoiceResponse.status = true;
        invoiceResponse.info = body['invoiceInfo'];
    }).catch(function (error) {
        invoiceResponse.error = error;
        apiException(invoiceResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},{});
    });
    return invoiceResponse;
}

export default GetInvoice;