import React, {createContext, useState} from 'react';

const AccountContext = createContext();

const AccountProvider = ({children}) => {
    const [accountId, setAccountId] = useState(() => {
        const accountAccountId = localStorage.getItem('accountId');
        return accountAccountId !== null ? accountAccountId : ''
    });
    const [accountEmail, setAccountEmail] = useState(() => {
        const accountAccountEmail = localStorage.getItem('accountEmail');
        return accountAccountEmail !== null ? accountAccountEmail : ''
    });
    const [accountType, setAccountType] = useState(() => {
        const accountAccountType = localStorage.getItem('accountType');
        return accountAccountType !== null ? accountAccountType : ''
    });

    const updateAccount = (accountSettings) => {
        for (const property in accountSettings) {
            if (accountSettings[property] === undefined && accountSettings[property] === null) {
                continue;
            }
            switch (property) {
                case "accountId":
                    setAccountId(accountSettings[property]);
                    localStorage.setItem('accountId', accountSettings[property]);

                    break;
                case "accountEmail":
                    setAccountEmail(accountSettings[property]);
                    localStorage.setItem('accountEmail', accountSettings[property]);
                    break;
                case "accountType":
                    setAccountType(accountSettings[property]);
                    localStorage.setItem('accountType', accountSettings[property]);
                    break;
                default:
                    break;
            }
        }
    }

    const defaultAccount = {
        accountId,
        accountEmail,
        accountType,
        updateAccount
    }

    return (
        <AccountContext.Provider value={defaultAccount}>
            {children}
        </AccountContext.Provider>
    );
}

export {AccountContext, AccountProvider};