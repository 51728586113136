import axios from "axios";
import {Auth} from "aws-amplify";
import {apiException} from "../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../data/Urls";

let targetingProfileResponse = {
    status: false,
    error: '',
    info: {}
};

const CreateTargetingProfile = async (advertiserId, lineItemId, profileParams) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const currentUserInfo = await Auth.currentUserInfo();
    const request = {
        "line_item_id": lineItemId,
        "advertiser_id": advertiserId,
        "profile_params": profileParams

    };

    await axios.post(getCatalyticsApiUrl()+'targeting-profile',
        request,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${token}`
            }
        }).then(async (responseCreate) => {
        if (responseCreate.data === undefined || responseCreate.data.body === undefined) {
            targetingProfileResponse.error = 'Empty response from POST targeting profile service';
            apiException(targetingProfileResponse.error,{'email': currentUserInfo["attributes"]["email"]},request,responseCreate);
            return;
        }

        let bodyCreate = JSON.parse(responseCreate['data']['body']);
        if (bodyCreate['profile_id'] === undefined) {
            targetingProfileResponse.error = 'Invalid response from POST targeting profile service';
            apiException(targetingProfileResponse.error,{'email': currentUserInfo["attributes"]["email"]},request,responseCreate);
            return;
        }
        targetingProfileResponse.status = true;
        targetingProfileResponse.info = bodyCreate;
    }).catch(function (error) {
        targetingProfileResponse.error = error;
        apiException(targetingProfileResponse.error,{'email': currentUserInfo["attributes"]["email"]},request,{});
        return;
    });
    return targetingProfileResponse;
}

export default CreateTargetingProfile;