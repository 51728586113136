import React from 'react';
//import FontAwesomeIcon from 'font-awesome';
import { ReactComponent as Play } from '../../../../assets/icons/play.svg';
import { ReactComponent as Pause } from '../../../../assets/icons/pause.svg';
import { updateLineItem } from '../api';

export default function StatusToggle(props) {
    function handleClick() {
        let updatedStatus = props.value === 'active' ? 'inactive' : 'active';
        updateLineItem(props.data.campaign.toString(), {
            state: updatedStatus,
        })
            .then(props.updateTable())
            .catch(console.log);
    }

    return props.value === 'active' ? (
        <Pause
            title="Pause campaign"
            onClick={handleClick}
            width={20}
            height={20}
            style={{ textAlign: 'center' }}
        />
    ) : (
        <Play
            title="Activate campaign"
            onClick={handleClick}
            width={20}
            height={20}
            style={{ textAlign: 'center' }}
        />
    );
}
