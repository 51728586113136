//import React, {useContext} from "react";
import React from "react";
import {useParams} from "react-router-dom";
import BusinessSearch from "./steps/business-search/BusinessSearch";
import CampaignSettings from "./steps/campaign-settings/CampaignSettings";
import CreativeUpload from "./steps/creative-upload/CreativeUpload";
import PayCampaign from "./steps/pay-campaign/PayCampaign";
/*import Tracking from "./steps/Tracking";
import Activate from "./steps/Activate";*/
import './create-campaign.css'
//import TargetIcon from "./target-icon/TargetIcon";
//import SelectedTargets from "./selected-targets/SelectedTargets";
//import {CampaignContext} from "../../context/CampaignContext";
import ThankYou from "./steps/thank-you/ThankYou";
import CreateCampaignTitleImage from './../../assets/images/create-campaign-title.svg';
/*import {AccountContext} from "../../context/AccountContext";
import {v4 as uuid} from "uuid";*/

const CreateCampaign = (props) => {
    let params = useParams();
    //const {cleanCampaign} = useContext(CampaignContext);


    const renderStep = () => {
        switch (parseInt(params.step)) {
            case 0:
                return <BusinessSearch/>
            case 1:
                return <CreativeUpload/>
            case 2:
                return <CampaignSettings/>
            case 3:
                return <PayCampaign/>
            case 4:
                return <ThankYou/>
            default:
                return <BusinessSearch/>
        }
    }


    return (<div className="step-box">
        <div className="create-campaign-title"><img src={CreateCampaignTitleImage} alt="Create Campaigns" /></div>
        {renderStep()}


    </div>)

    /*{(parseInt(params.step) !== 4) ? (<button className="restart-campaign-button" onClick={() => {
            cleanCampaign();
            window.location = '/';
        }}>Restart Campaign
        </button>) : ''}*/

}

export default CreateCampaign