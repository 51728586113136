import axios from "axios";
import {Auth} from "aws-amplify";
import * as Sentry from "@sentry/react";
import {apiException} from "../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../data/Urls";

let urlSegmentResponse = {
    status: false,
    error: '',
    info: {}
};

const CreateUrlSegment = async (advertiserId, name, targets) => {
    let urlList = [];
    for (const target of targets) {
        urlList.push({url: target['url']});
    }
    const currentUserInfo = await Auth.currentUserInfo();
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const request = {
        "name": name,
        "advertiser_id": advertiserId,
        "url_list": urlList
    };
    await axios.post(getCatalyticsApiUrl()+'create-url-segments',
        request,
        {
            headers: {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(async (responseCreate) => {
        if (responseCreate.data === undefined) {
            urlSegmentResponse.error = 'Empty response from POST url segment service';
            apiException(urlSegmentResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, responseCreate);
            return;
        }
        if (responseCreate.data['is_error'] !== undefined && responseCreate.data['is_error']) {
            urlSegmentResponse.error = 'Error response from POST url segment service';
            apiException(urlSegmentResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, responseCreate);
            return;
        }
        if (responseCreate.data['url_segment'] === undefined) {
            urlSegmentResponse.error = 'Segment information not found on response from url segment service';
            apiException(urlSegmentResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, responseCreate);
            return;
        }
        if (responseCreate.data['url_segment']['segment_id'] === undefined) {
            urlSegmentResponse.error = 'Segment id not found on response from url segment service';
            apiException(urlSegmentResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, responseCreate);
            return;
        }
        urlSegmentResponse.status = true;
        urlSegmentResponse.info = responseCreate.data['url_segment'];
    }).catch(function (error) {
        urlSegmentResponse.error = error;
        apiException(urlSegmentResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, {});
    });
    return urlSegmentResponse;
}

export default CreateUrlSegment;