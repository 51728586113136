import React, {useContext, useState} from "react";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import stripeProducts from '../../../data/StripeProducts';
import {Link} from "react-router-dom";
import './payment.css';
import {CampaignContext} from "../../../context/CampaignContext";
/*import axios from "axios";
import uuid from 'react-uuid'
import {Auth} from "aws-amplify";*/

const Payment = (props) => {
    const {
        isContinuous
    } = useContext(CampaignContext);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const stripe = useStripe();

    const elements = useElements();

    var moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const pay = async () => {

        if (!stripe || !elements) {
            return;
        }
        setLoading(true);
        setErrorMsg('');
        let resultUrl = (window.location.href.indexOf('localhost') === -1) ? 'https://app.catalytics.io/create-campaign/4' : 'http://localhost:3000/create-campaign/4';
        const result = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: resultUrl,
                },
            }
        );


        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
            setErrorMsg(result.error.message);
            setLoading(false);
            return;
        }

    }

    return (<div className="payment-section">
        <h3>Payment</h3>
        {(!props.changingSubscription) ? <div className="subtitle">How would you like to pay for your media placements?</div>:''}
        {(errorMsg !== '') ? (<div className="error-message">{errorMsg}</div>) : ''}
        <div className="form-container">
            {(!props.changingSubscription) ? <div className="stripe-element"><PaymentElement/></div> : ''}

            <div className="media-spend-summary-box">
                <div className="title">Media Spend Summary</div>
                {(props.changingSubscription && props.currentSubscription['planInfo']['price'] !== stripeProducts()[props.subscriptionId]['price']) ? (
                    <div className="update-current-subscription">You have a current subscription of
                        ${props.currentSubscription['planInfo']['price']}. We suggest to update your current
                        subscription to ${stripeProducts()[props.subscriptionId]['price']}</div>) : ''}
                {(props.showSubscription && !props.changingSubscription) ?
                    <div className="media-spend-info subscription">

                        <div className="media-spend-info-label">Monthly Subscription Plan:</div>
                        <div className="media-spend-info-value">
                            <select name="subscription" value={props.subscriptionId}
                                    onChange={e => props.subscriptionIdChangeHandler(e.target.value)}
                                    placeholder="Please select a budget">
                                {stripeProducts().map((product, key) => {
                                    /*if (key < props.subscriptionId) {
                                        return;
                                    }*/
                                    return <option key={key} value={key}>{moneyFormatter.format(product.price)}</option>
                                })}
                            </select>
                        </div>
                    </div> : ''}
                {(!props.changingSubscription) ? <div className="media-spend-info prepayment">
                    <div className="media-spend-info-label">{(props.showSubscription) ? 'Prepayment' : 'Payment'}:</div>
                    <div className="media-spend-info-value">${props.totalToPay}</div>
                </div> : ''}
                {(!props.changingSubscription) ? <div className="media-spend-info media-spend-info-border total">
                    <div className="media-spend-info-label">Total:</div>
                    <div className="media-spend-info-value">${props.totalToPay}</div>
                </div> : ''}
            </div>
            {(isContinuous && !props.changingSubscription) ? <div className="media-spend-summary-box">
                <div className="media-spend-info next">
                    <div className="media-spend-info-label">Next Billing:</div>
                    <div className="media-spend-info-value">${props.nextBilling}</div>
                </div>
            </div> : ''}

        </div>
        {(loading) ?
            (<div className="loader">Loading...</div>) :
            (
                <div className="step-buttons-container">
                    <div className="step-button-back">
                        <Link className="button secondary"
                              to="/create-campaign/2">Back</Link>
                    </div>

                    <div className="step-button-next">

                        {(!props.changingSubscription)?
                            <a href="#!" className="button" onClick={async () => await pay()}>Finish & Pay</a>:
                            <Link className="button" to="/create-campaign/4">Finish</Link>}
                    </div>

                </div>)}
    </div>);
}

export default Payment;