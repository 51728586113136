import React, {useState} from 'react';
import axios from "axios";
import {Auth} from "aws-amplify";
import {apiException} from "../../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../../data/Urls";

let paymentIntentResponse = {
    status: false,
    error: '',
    info: {}
};

const GetPaymentIntent = async (paymentIntent) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const currentUserInfo = await Auth.currentUserInfo();
    await axios.get(getCatalyticsApiUrl()+'stripe-payment-intent', {
            params: {
                paymentIntent: paymentIntent,
                "env": (process.env.REACT_APP_BUILD_ENV === 'development')?'development':''
            },
            headers: {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'application/json'
            }
        }
    ).then(async (responseGet) => {
        if (responseGet.data === undefined || responseGet.data.body === undefined) {
            paymentIntentResponse.error = 'Empty response from GET payment intent';
            apiException(paymentIntentResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},responseGet);
        }
        let body = JSON.parse(responseGet['data']['body']);
        if (body['paymentIntentInfo'] === undefined || body['paymentIntentInfo']['status'] === undefined) {
            paymentIntentResponse.error = 'Payment Intent info not returned from GET payment intent';
            apiException(paymentIntentResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},responseGet);
        }
        let paymentIntentStatus = body['paymentIntentInfo']['status'];
        if (paymentIntentStatus !== 'succeeded') {
            switch (paymentIntent.status) {
                /*TODO: define what to do if status of the payment is processing */
                case 'processing':
                    paymentIntentResponse.error = 'Payment Intent info returned processing';
                    apiException(paymentIntentResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},responseGet);
                    return;
                case 'requires_payment_method':
                    paymentIntentResponse.error = 'Payment Intent info returned requires_payment_method';
                    apiException(paymentIntentResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},responseGet);
                    return;
                default:/* unknown error */
                    paymentIntentResponse.error = 'Payment Intent info returned unknown error';
                    apiException(paymentIntentResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},responseGet);
                    return;
            }
        }
        paymentIntentResponse.status = true;
        paymentIntentResponse.info = body['paymentIntentInfo'];
    }).catch(function (error) {
        paymentIntentResponse.error = error;
        apiException(paymentIntentResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},{});
    });
    return paymentIntentResponse;
}

export default GetPaymentIntent;