import React, {useContext, useEffect, useState} from "react";
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Check from "../../../../assets/icons/Check";
import Mail from "../../../../assets/icons/Mail";
import Select from "../../../../assets/icons/Select";
import StepsBar from "../steps-bar/StepsBar";
import ProgressProvider from "../../../util/progress-provider/ProgressProvider";
import "./thank-you.css";
import {Link, NavLink} from 'react-router-dom'
import {Auth} from "aws-amplify";
import axios from "axios";
import {CampaignContext} from "../../../../context/CampaignContext";
import TrackingPixel from "../../../../api/services/tracking-pixel/TrackingPixel";
import CreateLineItem from "../../../../api/services/line-item/create-line-item/CreateLineItem";
import CreateTargetingProfile
    from "../../../../api/services/targeting-profile/create-targeting-profile/CreateTargetingProfile";
import CreateUrlSegment
    from "../../../../api/services/url-segment/create-url-segment/CreateUrlSegment";
import CreateInsertionOrder
    from "../../../../api/services/insertion-order/create-insertion-order/CreateInsertionOrder";
import GetPaymentIntent from "../../../../api/services/stripe/payment-intent/get-payment-intent/GetPaymentIntent";
import GetInsertionOrders from "../../../../api/services/insertion-order/get-insertion-orders/GetInsertionOrders";
import GetInvoice from "../../../../api/services/stripe/invoice/get-invoice/GetInvoice";
import CreateAdvertiser from "../../../../api/services/advertiser/create-advertiser/CreateAdvertiser";
import getCatalyticsApiUrl from "../../../../data/Urls";
import GetActiveSubscription
    from "../../../../api/services/stripe/subscription/get-active-subscription/GetActiveSubscription";
import UpdateSubscriptionPaymentMethod
    from "../../../../api/services/stripe/subscription/update-subscription/UpdateSubscriptionPaymentMethod";
//import {useStripe} from "@stripe/react-stripe-js";

const ThankYou = () => {
    let stripeCustomerIdStoredStr = (process.env.REACT_APP_BUILD_ENV === 'development') ? 'custom:StripeCustomerId' : 'custom:LiveStripeCustomerId';

    const {
        //budgetType,
        //budget,
        isContinuous,
        targets,
        ads,
        startDate,
        endDate,
        getTotalBudget,
        cleanCampaign,
        campaignIsReady,
        campaignId,
        updateAdProperty,
        insertionOrderId,
        universalPixel,
        updateCampaign,
        budgetType,
        activeCampaign
    } = useContext(CampaignContext);
    const [createCampaignLoading, setCreateCampaignLoading] = useState(true);
    const [advertiserId, setAdvertiserId] = useState('');
    const [thisInsertionOrderId, setThisInsertionOrderId] = useState('');
    const [error, setError] = useState('');
    const [loadingInfoMessages, setLoadingInfoMessages] = useState([]);
    const [errorLink, setErrorLink] = useState('/create-campaign/3');
    const [errorLinkMessage, setErrorLinkMessage] = useState('Back');
    const [percentage, setPercentage] = useState(66);
    //const stripe = useStripe();

    const notifyError = (errorMsg, goToLink, goToLinkMessage) => {
        setError(errorMsg);
        setErrorLink(goToLink);
        setErrorLinkMessage(goToLinkMessage);
        setCreateCampaignLoading(false);

    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDateLineItem(date, type) {
        return (
            [
                date.getFullYear(),
                padTo2Digits(date.getMonth() + 1),
                padTo2Digits(date.getDate()),
            ].join('-') +
            ' ' +
            [
                padTo2Digits((type === 'start') ? 0 : 23),
                padTo2Digits((type === 'start') ? 0 : 59),
                padTo2Digits((type === 'start') ? 0 : 59),
            ].join(':')
        );
    }

    const createLineItems = async (advertiserId, insertionOrderId, uploadedAds, universalPixel) => {

        let foundUrlTargets = false;
        let urlTargets = [];
        for (const target of targets) {
            if (target['place_id'] === undefined && target['url'] !== undefined) {
                urlTargets.push(target);
                foundUrlTargets = true;
            }
        }
        let urlSegmentId = '';
        const urlSegmentName = 'url-segment-for-order-' + insertionOrderId;
        if (foundUrlTargets) {
            let loadingMessage = 'Creating URL Segments';
            addLoadingInfo(loadingMessage);
            const createUrlSegmentResponse = await CreateUrlSegment(advertiserId, urlSegmentName, urlTargets);
            if (!createUrlSegmentResponse.status) {
                console.log(createUrlSegmentResponse.error);
                //notifyError('The campaign could not be created with the current settings', '/create-campaign/3', 'Back');
                //return;

            } else {
                //setLoadingInfoMessages([...loadingInfoMessages, 'URL Segments created']);
                //console.log(createUrlSegmentResponse.info);
                urlSegmentId = createUrlSegmentResponse.info['segment_id'];
            }
        }
        let pixels = [];
        pixels.push({'id': universalPixel['conversions']['site-visit']['conversion_pixel_id']});
        pixels.push({'id': universalPixel['conversions']['engaged-visit']['conversion_pixel_id']});
        pixels.push({'id': universalPixel['conversions']['conversion-action-complete']['conversion_pixel_id']});
        for await (const target of targets) {
            let creatives = [];
            for (const ad of uploadedAds) {
                creatives.push({'id': ad['vendorId'], 'all_budget_intervals': true, 'custom_date_ranges': null});
            }
            /*let targetStartDate = target['startDate'];
            let targetEndDate = target['endDate'];
            if (targetStartDate === undefined) {
                targetStartDate = startDate;
                //targetEndDate = getRealEndDate();
            }*/
            let targetStartDate = startDate;

            /*if (targetEndDate === undefined) {
                targetEndDate = new Date(targetStartDate.year, targetStartDate.month, targetStartDate.day);
                targetEndDate.setMonth(targetEndDate.getMonth() + 1);
            }*/
            let targetEndDate = getRealEndDate();
            targetEndDate = adjustLineItemEndDate(targetEndDate, getRealEndDate());

            let targetName = target['name'];
            if (targetName === undefined) {
                targetName = target['title'];
            }
            let dailyBudget = null;
            let lifetimeBudget = null;
            (budgetType === 'day') ? dailyBudget = target['budget'] : lifetimeBudget = target['budget'];
            let loadingMessage = 'Creating Campaign ' + targetName + '...';
            addLoadingInfo(loadingMessage);
            const createLineItemResponse = await CreateLineItem(advertiserId, targetName, insertionOrderId, creatives, pixels, formatDateLineItem(targetStartDate, 'start'), formatDateLineItem(targetEndDate, 'end'), lifetimeBudget, dailyBudget);
            if (!createLineItemResponse.status) {
                console.log(createLineItemResponse.error);
                notifyError('The campaign could not be created with the current settings', '/create-campaign/3', 'Back');
                return;
            }
            //setLoadingInfoMessages([...loadingInfoMessages, 'Campaign created correctly']);
            let lineItemId = createLineItemResponse.info['line_items']['id'];
            let profileParams = {};
            if (target['place_id'] !== undefined) {
                let target_latitude = target['geometry']['location']['lat'];
                target_latitude = target_latitude.toFixed(4);
                target_latitude = Number(target_latitude);
                let target_longitude = target['geometry']['location']['lng'];
                target_longitude = target_longitude.toFixed(4)
                target_longitude = Number(target_longitude);
                console.log(target['geometry']['location']);
                console.log("target_latitude="+target_latitude)
                console.log("target_longitude="+target_longitude)
                profileParams = {
                    "location_target_latitude": target_latitude,
                    "location_target_longitude": target_longitude,
                    "location_target_radius": 50,
                    "graph_id": 4,
                    "country_action": "include",
                    "country_targets": [
                        {
                            "id": 233,
                            "name": "United States",
                            "code": "US"
                        }
                    ],
                    "segment_group_targets": [
                        {
                            "boolean_operator": "and",
                            "segments": [
                                {
                                    "id": 839975,
                                    "action": "exclude",
                                    "expire_minutes": 0,
                                    "code": "18164"
                                }
                            ]
                        }
                    ],
                    "active": true
                }

            } else {
                profileParams = {
                    "country_targets": [
                        {
                            "id": 233,
                            "name": "United States",
                            "code": "US"
                        }
                    ],
                    "segment_boolean_operator": "and",
                    "segment_targets": [
                        {
                            "id": urlSegmentId,
                            "code": null,
                            "name": urlSegmentName,
                            "action": "include",
                            "start_minutes": 0,
                            "expire_minutes": -1,
                            "other_less": null,
                            "other_greater": null,
                            "other_equals": null
                        }
                    ]
                }
            }
            if ((urlSegmentId === '' && target['place_id'] === undefined)||(profileParams['location_target_latitude']===undefined)) {
                setCreateCampaignLoading(false);
                cleanCampaign(activeCampaign);
                return;
            } else {
                let loadingMessage = 'Creating targeting profile for campaign ' + targetName + '';
                addLoadingInfo(loadingMessage);
                const createTargetingProfile = await CreateTargetingProfile(advertiserId, lineItemId, profileParams);
                if (!createTargetingProfile.status) {
                    console.log('Targeting Profile could not be created');
                    console.log(createTargetingProfile.error);
                    notifyError('The campaign could not be created with the current settings', '/create-campaign/3', 'Back');
                    return;
                }
                //setLoadingInfoMessages([...loadingInfoMessages, 'Targeting profile created']);
                //updateCampaign({'lineItems': createLineItemResponse.info});
            }
        }
        setCreateCampaignLoading(false);
        cleanCampaign(activeCampaign);
    }

    const formatDate = (date) => {
        return date.toISOString().split('T')[0]
    }

    function getRealEndDate() {
        /*if (isContinuous || endDate === undefined || endDate <= startDate) {
            let startDateCopy = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            startDateCopy.setMonth(startDateCopy.getMonth() + 1);
            return startDateCopy;
        }
        return endDate;*/
        if (isContinuous || endDate === undefined || endDate <= startDate) {
            return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 23, 59, 59);
        }
        if (startDate.getMonth !== endDate.getMonth()) {
            return new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 23, 59, 59);
        }
        return endDate;
    }

    function adjustLineItemEndDate(lineItemEndDate, globalEndDate) {
        let testEndDate = new Date(lineItemEndDate.getFullYear(), lineItemEndDate.getMonth(), lineItemEndDate.getDate(), 23, 59, 59);
        if (testEndDate.getTime() > globalEndDate.getTime()) {
            let endDateCopy = new Date(globalEndDate.getFullYear(), globalEndDate.getMonth(), globalEndDate.getDate());
            endDateCopy.setDate(endDateCopy.getDate() - 1);
            return endDateCopy;
        }
        return lineItemEndDate;
    }

    const getCampaignFingerPrint = (advertiserId) => {
        return advertiserId.toString() + '/' + getTotalBudget().toString() + '/' + formatDate(startDate) + '/' + formatDate(getRealEndDate()) + '/' + targets.length + '/' + ads.length;
    }

    const cleanString = (input) => {
        return input.replace(/[^A-Z0-9]/ig, "_");
    }

    const createAds = async (advertiserId) => {
        const credentials = await Auth.currentUserCredentials();
        let adsCopy = ads;
        const identityId = credentials.identityId;
        for await (const [adIndex, ad] of ads.entries()) {
            if (ad['vendorId'] !== undefined && ad['vendorId'] !== '') {
                adsCopy[adIndex]['vendorId'] = ad['vendorId'];
                continue;
            }
            let loadingMessage = 'Uploading ad ' + ad['name'] + '';
            addLoadingInfo(loadingMessage);
            await axios.post(getCatalyticsApiUrl() + 'creative-media',
                {
                    "body": JSON.stringify({
                        "advertiserId": advertiserId.toString(),
                        "creative": {
                            "template": {"id": 4},
                            "width": ad['width'],
                            "height": ad['height'],
                            "click_url": ad['landingPage'],
                            "file_name": ad['name'],
                            "name": ad['name'],
                            "identity_id": identityId,
                            "s3_file_name": ad['name']
                        }
                    })
                },
                {
                    headers: {
                        'Content-Type': 'text/plain'
                    }
                }).then(async (response) => {
                if (response.data !== undefined && response.data.body !== undefined) {
                    let responseBody = JSON.parse(response.data.body);
                    if (responseBody['creative'] !== undefined && responseBody['creative']['status'] !== undefined && responseBody['creative']['status'] === 'OK' && responseBody['creative']['id'] !== undefined && responseBody['creative']['id'] !== '') {
                        //setLoadingInfoMessages([...loadingInfoMessages, 'Ad uploaded']);
                        adsCopy[adIndex]['vendorId'] = responseBody['creative']['id'];
                        updateAdProperty(adIndex, 'vendorId', responseBody['creative']['id'])
                    } else {
                        notifyError('The campaign could not be created with the current settings', '/create-campaign/3', 'Back');
                    }
                } else {
                    notifyError('The campaign could not be created with the current settings', '/create-campaign/3', 'Back');
                }
            }).catch(function (error) {
                notifyError(error.toString(), '/create-campaign/3', 'Back');
            });
        }
        return adsCopy;
    }

    const getUniversalPixel = async (advertiserId, name) => {
        if (universalPixel != null) {
            return universalPixel;
        }
        //setLoadingInfoMessages([...loadingInfoMessages, 'Creating tracking pixel...']);
        let loadingMessage = 'Creating tracking pixel';
        addLoadingInfo(loadingMessage);
        const trackingPixelResponse = await TrackingPixel(advertiserId, name);
        if (!trackingPixelResponse.status) {
            console.log(trackingPixelResponse.error);
            notifyError('The campaign could not be created with the current settings', '/create-campaign/3', 'Back');
            return;
        }
        //setLoadingInfoMessages([...loadingInfoMessages, 'Tracking pixel created']);
        updateCampaign({'universalPixel': trackingPixelResponse.info});
        return trackingPixelResponse.info;
    }

    const createInsertionOrder = async (advertiserId, insertionOrderAmount, invoiceId) => {
        let campaignFingerPrint = getCampaignFingerPrint(advertiserId);
        const createdCampaignsStored = localStorage.getItem('createdCampaigns');
        if (createdCampaignsStored !== null) {
            let createdCampaigns = JSON.parse(createdCampaignsStored)
            if (createdCampaigns.includes(campaignFingerPrint)) {
                notifyError('The campaign already exists', '/create-campaign/3', 'Back');
                return;
            }
        }
        let currentUserInfo = await Auth.currentUserInfo();
        if (insertionOrderId !== '' && insertionOrderId !== 0) {
            setThisInsertionOrderId(insertionOrderId);
            const uploadedAds = await createAds(advertiserId);
            const universalPixel = await getUniversalPixel(advertiserId, 'Pixel for ' + currentUserInfo["attributes"]["given_name"]);
            await createLineItems(advertiserId, insertionOrderId, uploadedAds, universalPixel);
            return;
        }
        const advertiserName = cleanString(currentUserInfo["attributes"]["given_name"]) + '_' + cleanString(currentUserInfo["attributes"]["family_name"]);
        let loadingMessage = 'Creating Insertion Order';
        addLoadingInfo(loadingMessage);
        const createInsertionOrderResponse = await CreateInsertionOrder(advertiserId, advertiserName, insertionOrderAmount, formatDate(startDate), formatDate(getRealEndDate()), campaignId, invoiceId);
        if (!createInsertionOrderResponse.status) {
            console.log(createInsertionOrderResponse.error);
            notifyError('The campaign could not be created with the current settings', '/create-campaign/3', 'Back');
            return;
        }
        //setLoadingInfoMessages([...loadingInfoMessages, 'Insertion Order created']);
        await postCreateInsertionOrderActions(advertiserId, createInsertionOrderResponse.info['id']);
    }

    const addLoadingInfo = (newMessage) => setLoadingInfoMessages(state => [...state, newMessage]);

    const findInsertionOrderWithInvoice = async (advertiserId, invoiceId) => {
        const insertionOrdersResponse = await GetInsertionOrders(advertiserId); //, invoiceId
        let loadingMessage = 'Looking for existing orders';
        addLoadingInfo(loadingMessage);
        if (!insertionOrdersResponse.status) {
            console.log(insertionOrdersResponse.error);
            notifyError('The campaign could not be created with the current settings', '/create-campaign/3', 'Back');
            return;
        }
        if (insertionOrdersResponse.info['insertion-orders'].length > 0) {
            for (let insertionOrder of insertionOrdersResponse.info['insertion-orders']) {
                if (insertionOrder['billing_code'] === null) {
                    continue;
                }
                if (insertionOrder['billing_code'].indexOf(invoiceId) !== -1) {
                    return insertionOrder['id'];
                }
            }
        }
        return 0;
    }

    const postCreateInsertionOrderActions = async (advertiserId, insertionOrderId) => {
        let currentUserInfo = await Auth.currentUserInfo();
        let campaignFingerPrint = getCampaignFingerPrint(advertiserId);
        const createdCampaignsStored = localStorage.getItem('createdCampaigns');
        let createdCampaigns = [];
        if (createdCampaignsStored !== null) {
            createdCampaigns = JSON.parse(createdCampaignsStored)
        }
        createdCampaigns.push(campaignFingerPrint);
        localStorage.setItem('createdCampaigns', JSON.stringify(createdCampaigns));
        updateCampaign({insertionOrderId: insertionOrderId});
        setThisInsertionOrderId(insertionOrderId);
        const uploadedAds = await createAds(advertiserId);
        const universalPixel = await getUniversalPixel(advertiserId, 'Pixel for ' + currentUserInfo["attributes"]["given_name"]);
        await createLineItems(advertiserId, insertionOrderId, uploadedAds, universalPixel);
    }

    useEffect(() => {

        var queryString = window.location.search;
        let params = new URLSearchParams(queryString);
        let paymentIntent = params.get("payment_intent");
        let paymentIntentClientSecret = params.get("payment_intent_client_secret");
        /*if (paymentIntent === undefined || paymentIntent === '' || paymentIntentClientSecret === undefined || paymentIntentClientSecret === '') {
            notifyError('You have reached this page without completing payment. Please go back and complete your payment.', '/create-campaign/3', 'Back');
            return;
        }*/
        if (!campaignIsReady) {
            notifyError('No campaign is pending payment. Your campaign might have already been created.', '/control-room', 'Check Control Room');
            return;
        }
        (async () => {
            let createInsertionOrderBool = false;
            let foundInsertionOrderId = 0;
            let insertionOrderAmount = getTotalBudget();
            let foundSubscription = false;
            let invoiceId = '';
            let subscriptionId = '';
            let currentUserInfo = await Auth.currentUserInfo();
            if (currentUserInfo["attributes"][stripeCustomerIdStoredStr] !== undefined && currentUserInfo["attributes"][stripeCustomerIdStoredStr] !== '') {
                const activeSubscriptionResponse = await GetActiveSubscription(currentUserInfo["attributes"][stripeCustomerIdStoredStr]);
                if (!activeSubscriptionResponse.status) {
                    console.log(activeSubscriptionResponse.error);
                }
                if (activeSubscriptionResponse['info'] !== undefined && activeSubscriptionResponse['info']['plan'] !== undefined) {
                    foundSubscription = true;
                    subscriptionId = activeSubscriptionResponse['info']['id']
                    let latestInvoice = activeSubscriptionResponse['info']['latest_invoice'];
                    const invoiceResponse = await GetInvoice(latestInvoice);
                    if (invoiceResponse.status) {
                        insertionOrderAmount = invoiceResponse.info['amount_due'] / 100;
                        invoiceId = invoiceResponse.info['id'];
                    }
                }
            }

            if (!isContinuous) {
                createInsertionOrderBool = true;

            }
            if (!isContinuous || (isContinuous && !foundSubscription) || (isContinuous && paymentIntent !== undefined && paymentIntent !== null && paymentIntentClientSecret !== undefined && paymentIntentClientSecret !== null)) {
                if (paymentIntent === undefined || paymentIntent === '' || paymentIntentClientSecret === undefined || paymentIntentClientSecret === '') {
                    notifyError('You have reached this page without completing payment. Please go back and complete your payment.', '/create-campaign/3', 'Back');
                    return;
                }
                const paymentIntentResponse = await GetPaymentIntent(paymentIntent);
                if (!paymentIntentResponse.status) {
                    console.log(paymentIntentResponse.error);
                    notifyError('Sorry. The payment failed. Please try again.', '/create-campaign/3', 'Back');
                    return;
                }
                createInsertionOrderBool = true;
                invoiceId = paymentIntentResponse['info']['charges']['data'][0]['invoice'];
                insertionOrderAmount = paymentIntentResponse['info']['amount'] / 100;
                if (isContinuous && foundSubscription && subscriptionId === '') {
                    const updateSubscriptionResponse = await UpdateSubscriptionPaymentMethod(subscriptionId, paymentIntentResponse['info']['payment_method']);
                    if (!updateSubscriptionResponse.status) {
                        console.log(paymentIntentResponse.error);
                        notifyError('Sorry. The payment method could not be used for the subscription.', '/create-campaign/3', 'Back');
                    }
                }
            }

            let advertiserId = '';
            if (currentUserInfo['attributes'] === undefined || currentUserInfo["attributes"]["custom:AdvertiserId"] === undefined || currentUserInfo["attributes"]["custom:AdvertiserId"] === '') {
                const advertiserResponse = await CreateAdvertiser();
                if (!advertiserResponse.status) {
                    console.log(advertiserResponse.error);
                    notifyError('Sorry. The advertiser could not be created. Please try again.', '/create-campaign/3', 'Back');
                    return;
                }
                advertiserId = advertiserResponse.info['id'];
                let user = await Auth.currentAuthenticatedUser();
                setAdvertiserId(advertiserId);
                await Auth.updateUserAttributes(user, {
                    'custom:AdvertiserId': advertiserId.toString()
                });
            } else {
                setAdvertiserId(currentUserInfo["attributes"]["custom:AdvertiserId"]);
                advertiserId = currentUserInfo["attributes"]["custom:AdvertiserId"];
            }
            if (isContinuous && foundSubscription && invoiceId !== '' && invoiceId !== undefined) {
                let insertionOrderIdWithInvoice = await findInsertionOrderWithInvoice(advertiserId, invoiceId);
                console.log('found insertion order ' + insertionOrderIdWithInvoice);
                if (insertionOrderIdWithInvoice !== 0) {
                    foundInsertionOrderId = insertionOrderIdWithInvoice;
                    createInsertionOrderBool = false;
                } else {
                    createInsertionOrderBool = true;
                }
            }

            if (createInsertionOrderBool) {
                await createInsertionOrder(advertiserId, insertionOrderAmount, invoiceId);
            } else {
                await postCreateInsertionOrderActions(advertiserId, foundInsertionOrderId);
            }

        })();


    }, []);

    return (

        <div className="thank-you-container">
            <StepsBar step="5"/>
            {(createCampaignLoading) ? (<div className="loading-container">

                    <div className="all-progress-bars-container">
                        {loadingInfoMessages.map(entry =>
                            <div className="progress-bar-container">
                                <ProgressProvider valueStart={0} valueEnd={100}>
                                    {value => <CircularProgressbar strokeWidth={4} value={value} text={`${value}`}
                                                                   styles={{
                                                                       root: {},
                                                                       path: {
                                                                           stroke: `rgba(219, 193, 137, ${percentage / 100})`
                                                                       },
                                                                       trail: {
                                                                           stroke: '#fffff',
                                                                       },
                                                                       text: {
                                                                           fill: '#fff',
                                                                           fontSize: '16px',
                                                                           fontFamily: 'Catalytics Extended Demi'
                                                                       }
                                                                   }}/>}
                                </ProgressProvider>
                                <div className="progress-label">{entry}</div>
                            </div>
                        )}
                    </div>
                    <div className="loader">Loading...</div>
                </div>) :
                (error !== '') ?
                    (<div className="error-message">{error} <Link
                        to={errorLink}>{errorLinkMessage}</Link></div>) :
                    (<div className="content">
                        <h3 className="section-title">Order Completed #<strong>{thisInsertionOrderId}</strong>. Next
                            Steps
                        </h3>
                        <div className="steps-container">
                            <div className="step">
                                <div className="icon-container">
                                    <Mail/>
                                </div>
                                <div className="content-container">
                                    <h3>Check for your confirmation email</h3>
                                    <div className="details">
                                        You’ll receive an email confirming your account has been set up.
                                        If you don’t see the email please check your spam folder and
                                        remove it from spam so that future email regarding your account
                                        will come through.
                                    </div>
                                    <div className="sub-details">
                                        Still don’t see an email? Send us one at <a href="#!">support@catalytics.com</a>
                                    </div>
                                </div>
                            </div>
                            <div className="step">
                                <div className="icon-container">
                                    <Select/>
                                </div>
                                <div className="content-container">
                                    <h3>Setup conversion tracking</h3>
                                    <div className="details">
                                        Be sure to add your selected conversion tracking option from
                                        step 3 to your site. We also include instructions in your
                                        welcome email and can provide assistance if you have any
                                        questions.
                                    </div>
                                </div>
                                <div className="button">VIEW CONVERSION INSTRUCTIONS</div>
                            </div>
                            <div className="step">
                                <div className="icon-container">
                                    <Check/>
                                </div>
                                <div className="content-container">
                                    <h3>Confirm your ad creative</h3>
                                    <div className="details">
                                        If you are coming back to upload more ad creatives you can do
                                        that from your <NavLink to="/control-room">Control Room</NavLink>. If you have
                                        requested help from the team, an advertising specialist will
                                        reach out to you within two business days. Learn more about the
                                        Control Room and how to manage your campaigns here.
                                    </div>
                                </div>
                                <div className="button">LEARN MORE</div>
                            </div>
                        </div>
                    </div>)}
        </div>
    );
};

export default ThankYou;
