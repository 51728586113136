import React, {useState, createContext} from 'react';


const CampaignContext = createContext();

const CampaignProvider = ({children}) => {
    /*const [searchBusinessKeywordTarget, setSearchBusinessKeywordTarget] = useState("");
    const [searchArticlesKeywordTarget, setSearchArticlesKeywordTarget] = useState("");*/


    const [campaigns, setCampaigns] = useState(() => {
        const campaignList = localStorage.getItem('campaignList');
        return campaignList !== null ? JSON.parse(campaignList) : []
    });
    const [activeCampaign, setActiveCampaign] = useState(() => {
        const campaignActiveCampaign = localStorage.getItem('campaignActiveCampaign');
        return campaignActiveCampaign !== null ? campaignActiveCampaign : ''
    });
    const getLocalStoredCampaignInfo = (key) => {
        return localStorage.getItem(key + '_' + activeCampaign);
    }

    const [targets, setTargets] = useState(() => {
        const campaignTargets = getLocalStoredCampaignInfo('campaignTargets');
        return campaignTargets !== null ? JSON.parse(campaignTargets) : []
    });
    const [countBusinessTargets, setCountBusinessTargets] = useState(() => {
        const campaignCountBusinessTargets = getLocalStoredCampaignInfo('campaignCountBusinessTargets');
        return campaignCountBusinessTargets !== null ? campaignCountBusinessTargets : 0
    });
    const [countArticleTargets, setCountArticleTargets] = useState(() => {
        const campaignCountArticleTargets = getLocalStoredCampaignInfo('campaignCountArticleTargets');
        return campaignCountArticleTargets !== null ? campaignCountArticleTargets : 0
    });
    const [searchTargetsResults, setSearchTargetsResults] = useState(() => {
        const campaignSearchTargetsResults = getLocalStoredCampaignInfo('campaignSearchTargetsResults');
        return campaignSearchTargetsResults !== null ? JSON.parse(campaignSearchTargetsResults) : []
    });
    const [resultsTableType, setResultsTableType] = useState(() => {
        const campaignResultsTableType = getLocalStoredCampaignInfo('campaignResultsTableType');
        return campaignResultsTableType !== null ? campaignResultsTableType : 'search';
    });
    const [ads, setAds] = useState(() => {
        const campaignAds = getLocalStoredCampaignInfo('campaignAds');
        return campaignAds !== null ? JSON.parse(campaignAds) : []
    });
    const [campaignId, setCampaignId] = useState(() => {
        const campaignCampaignId = getLocalStoredCampaignInfo('campaignId');
        const uniqId = "id" + Math.random().toString(16).slice(2);
        return campaignCampaignId !== null ? campaignCampaignId : uniqId;
    });

    const [landingPage, setLandingPage] = useState(() => {
        const campaignLandingPage = getLocalStoredCampaignInfo('campaignLandingPage');
        return campaignLandingPage !== null ? campaignLandingPage : '';
    });
    const [conversionEvent, setConversionEvent] = useState(() => {
        const campaignConversionEvent = getLocalStoredCampaignInfo('campaignConversionEvent');
        return campaignConversionEvent !== null ? campaignConversionEvent : '';
    });
    /*const [s3Directory, setS3Directory] = useState(() => {
        const campaignS3Directory = localStorage.getItem('campaignS3Directory');
        return campaignS3Directory !== null ? campaignS3Directory : ''
    });*/
    const [budgetType, setBudgetType] = useState(() => {
        const campaignBudgetType = getLocalStoredCampaignInfo('campaignBudgetType');
        return campaignBudgetType !== null ? campaignBudgetType : 'day';
    });
    const [budget, setBudget] = useState(() => {
        const campaignBudget = getLocalStoredCampaignInfo('campaignBudget');
        return campaignBudget !== null ? campaignBudget : '';
    });
    const [budgetId, setBudgetId] = useState(() => {
        const campaignBudgetId = getLocalStoredCampaignInfo('campaignBudgetId');
        return campaignBudgetId !== null ? campaignBudgetId : 9999;
    });


    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }


    function getNextBusinessDay(date) {
        date = new Date(+date);
        do {
            date.setDate(date.getDate() + 1);
        } while (!(date.getDay() % 6))
        return date;
    }

    const [startDate, setStartDate] = useState(() => {
        const campaignStartDate = getLocalStoredCampaignInfo('campaignStartDate');
        return campaignStartDate !== null ? new Date(JSON.parse(campaignStartDate)) : getNextBusinessDay(new Date());
    });
    const [endDate, setEndDate] = useState(() => {
        const campaignEndDate = getLocalStoredCampaignInfo('campaignEndDate');
        return campaignEndDate !== null ? new Date(JSON.parse(campaignEndDate)) : getNextBusinessDay(new Date());
    });
    const [isContinuous, setIsContinuous] = useState(() => {
        const campaignIsContinuous = getLocalStoredCampaignInfo('campaignIsContinuous');
        return campaignIsContinuous !== null ? JSON.parse(campaignIsContinuous) : true;
    });

    const [conversionTracking, setConversionTracking] = useState(() => {
        const campaignConversionTracking = getLocalStoredCampaignInfo('campaignConversionTracking');
        return campaignConversionTracking !== null ? campaignConversionTracking : "default";
    });
    const [insertionOrderId, setInsertionOrderId] = useState(() => {
        const campaignInsertionOrderId = getLocalStoredCampaignInfo('campaignInsertionOrderId');
        return campaignInsertionOrderId !== null ? campaignInsertionOrderId : '';
    });
    const [universalPixel, setUniversalPixel] = useState(() => {
        const campaignUniversalPixel = getLocalStoredCampaignInfo('campaignUniversalPixel');
        return campaignUniversalPixel !== null ? JSON.parse(campaignUniversalPixel) : null;
    });
    //const [currentCreateCampaignStep, setCurrentCreateCampaignStep] = useState(0);
    const [resetBudget, setResetBudget] = useState(0);
    const [resetDates, setResetDates] = useState(0);
    const [assignedBudget, setAssignedBudget] = useState(() => {
        let assignedBudget = 0;
        for (let target of targets) {
            if (target['budget'] !== undefined && isNumeric(target['budget'])) {
                assignedBudget += parseFloat(target['budget']);
            } else {
                if (target['budget'] !== '') {
                    assignedBudget += parseFloat(budget / targets.length);
                }

            }
        }
        return assignedBudget;
    });
    const [remainingBudget, setRemainingBudget] = useState(() => {
        return (budget - assignedBudget);
    });

    function checkIsValidUrl(string) {
        if (string.indexOf('.') === -1) {
            return false;
        }
        if (string.substring(string.length - 1) === '.') {
            return false;
        }
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    const checkAdsHaveValidLandingPages = (adsToUse) => {
        if (typeof adsToUse === typeof undefined) {
            adsToUse = ads;
        }
        if (adsToUse.length === 0) {
            return false;
        }
        for (const ad of adsToUse) {
            if (ad['landingPage'] === undefined) {
                return false;
            }
            if (!checkIsValidUrl(ad['landingPage'])) {
                return false;
            }
        }
        return true;
    }
    const [adsHaveValidLandingPages, setAdsHaveValidLandingPages] = useState(() => {

        return checkAdsHaveValidLandingPages();
    });
    const checkAdsHaveValidAdSizes = (adsToUse) => {
        if (typeof adsToUse === typeof undefined) {
            adsToUse = ads;
        }
        if (adsToUse.length === 0) {
            return false;
        }
        for (const ad of adsToUse) {
            if (ad['width'] === undefined) {
                return false;
            }
            if (ad['height'] === undefined) {
                return false;
            }
        }
        return true;
    }
    const [adsHaveValidAdSizes, setAdsHaveValidAdSizes] = useState(() => {

        return checkAdsHaveValidAdSizes();
    });
    const checkCampaignIsReady = (enteredBudget,enteredTargets) => {
        if (typeof enteredBudget === typeof undefined) {
            enteredBudget = budget;
        }
        if (typeof enteredTargets === typeof undefined) {
            enteredTargets = targets;
        }
        if (enteredTargets.length > 0 && ads.length > 0 && enteredBudget > 0 && (enteredTargets.length / enteredBudget) > 0) {
            //let foundAds = false;
            for (const [targetId, target] of enteredTargets.entries()) {
                if (target['ads'] === undefined || target['ads'].length === 0) {
                    return false;
                }
                if (target['budget'] !== undefined && target['budget'] <= 0) {
                    return false;
                }

            }
            return true;
        }
        return false;
    }
    const [campaignIsReady, setCampaignIsReady] = useState(()=>{
        return checkCampaignIsReady();
    });

    const addToAds = (newAds) => {
        setAds([...ads, ...newAds]);
        localStorage.setItem('campaignAds_' + activeCampaign, JSON.stringify([...ads, ...newAds]));
    }

    const removeFromAds = (ad) => {
        //let adsCopy = [...ads];
        let adsCopy = JSON.parse(JSON.stringify(ads));
        let index = ads.indexOf(ad);
        if (index !== -1) {
            adsCopy.splice(index, 1);
            setAds(adsCopy);
            localStorage.setItem('campaignAds_' + activeCampaign, JSON.stringify(adsCopy));
        }
    }

    const updateAdUrl = (index, url) => {
        //let adsCopy = [...ads];
        let adsCopy = JSON.parse(JSON.stringify(ads));
        adsCopy[index]['url'] = url;
        setAds(adsCopy);
        localStorage.setItem('campaignAds_' + activeCampaign, JSON.stringify(adsCopy));
    }

    const removeFromTargets = (type, row) => {
        let newSelectedRows = []
        targets.forEach((selectedRow) => {
            if (type === 'business-search') {
                if (selectedRow['place_id'] !== undefined && (row['place_id'] === selectedRow['place_id'])) {
                } else {
                    newSelectedRows.push(selectedRow);

                }
            } else {
                if (selectedRow['url'] !== undefined && (row['url'] === selectedRow['url'])) {
                } else {
                    newSelectedRows.push(selectedRow)
                }
            }

        });
        updateCampaign({targets: newSelectedRows});

    }

    const removeAllAdsFromTargets = () => {
        let targetsCopy = JSON.parse(JSON.stringify(targets));
        for (const [targetId, target] of targets.entries()) {
            targetsCopy[targetId]['ads'] = [];
        }
        updateCampaign({targets: targetsCopy});
    }

    const assignAllAdsToTargets = (adsList) => {
        let targetsCopy = JSON.parse(JSON.stringify(targets));
        for (const [targetId, target] of targets.entries()) {
            targetsCopy[targetId]['ads'] = adsList;
        }
        updateCampaign({targets: targetsCopy});
    }

    const updateTargetProperty = (targetId, property, value) => {
        let targetsCopy = JSON.parse(JSON.stringify(targets));
        if (property === 'startDate' || property === 'endDate') {
            let b = value.split(/\D/);
            let date = new Date(b[0], --b[1], b[2]);
            targetsCopy[targetId][property] = date;
        } else if (property === 'ads') {
            targetsCopy[targetId][property] = value;
        } else if (property === 'isContinuous') {
            targetsCopy[targetId][property] = value;
        } else {
            targetsCopy[targetId][property] = value;
        }
        updateCampaign({targets: targetsCopy});
    }

    const updateAdDimensions = (adId, width, height) => {
        let adsCopy = JSON.parse(JSON.stringify(ads));
        adsCopy[adId]['width'] = width;
        adsCopy[adId]['height'] = height;
        setAdsHaveValidAdSizes(checkAdsHaveValidAdSizes(adsCopy));
        updateCampaign({ads: adsCopy});
    }

    const updateAdProperty = (adId, property, value) => {
        let adsCopy = JSON.parse(JSON.stringify(ads));
        adsCopy[adId][property] = value;
        if (property === 'landingPageUri') {
            let protocol = 'https://';
            if (adsCopy[adId]['landingPageProtocol'] !== undefined) {
                protocol = adsCopy[adId]['landingPageProtocol'];
            }
            let landingPageUri = adsCopy[adId][property].replaceAll('https://', '');
            landingPageUri = landingPageUri.replaceAll('http://', '');
            adsCopy[adId][property] = landingPageUri;
            adsCopy[adId]['landingPage'] = protocol + landingPageUri;
            setAdsHaveValidLandingPages(checkAdsHaveValidLandingPages(adsCopy));
            setAdsHaveValidAdSizes(checkAdsHaveValidAdSizes(adsCopy));
        }
        if (property === 'landingPageProtocol') {
            if (adsCopy[adId]['landingPageuri'] !== undefined) {
                adsCopy[adId]['landingPage'] = adsCopy[adId]['landingPageProtocol'] + adsCopy[adId]['landingPageuri'];
            }
            setAdsHaveValidLandingPages(checkAdsHaveValidLandingPages(adsCopy));
            setAdsHaveValidAdSizes(checkAdsHaveValidAdSizes(adsCopy));
        }
        updateCampaign({ads: adsCopy});
    }

    const cleanCampaign = (campaignToClean) => {
        const localStorageVariables = [
            'campaignTargets',
            'campaignCountBusinessTargets',
            'campaignCountArticleTargets',
            'campaignResultsTableType',
            'campaignSearchTargetsResults',
            'campaignAds',
            'campaignId',
            'campaignLandingPage',
            'campaignBudget',
            'campaignBudgetId',
            'campaignBudgetType',
            'campaignConversionEvent',
            'campaignStartDate',
            'campaignEndDate',
            'campaignIsContinuous',
            'campaignConversionTracking',
            //'campaignUniversalPixel', //the campaign universal pixel should not be removed
            'campaignInsertionOrderId'
        ];

        for (const localStorageVariable of localStorageVariables) {
            localStorage.removeItem(localStorageVariable + '_' + campaignToClean);
        }
        let newCampaigns = [];
        for (const campaign of campaigns) {
            if (campaign !== campaignToClean) {
                newCampaigns.push(campaign);
            }
        }
        updateCampaign({'campaigns': newCampaigns});
        setTargets([]);
        setCountBusinessTargets(0);
        setCountArticleTargets(0);
        setResultsTableType('search');
        setSearchTargetsResults([]);
        setAds([]);
        const uniqId = "id" + Math.random().toString(16).slice(2);
        setCampaignId(uniqId);
        setLandingPage('');
        setBudget('');
        setBudgetId(null);
        setBudgetType('day');
        setStartDate(getNextBusinessDay(new Date()));
        setEndDate(getNextBusinessDay(new Date()));
        setIsContinuous(true);
        setConversionTracking(true)
        setCampaignIsReady('default');
        //setUniversalPixel(null);//keep campaign tracking pixel
        setInsertionOrderId('');
    }

    const saveLocalStorage = (key, value, activeCampaignStr) => {
        if (typeof activeCampaignStr === typeof undefined || activeCampaignStr === '') {
            activeCampaignStr = activeCampaign;
        }
        localStorage.setItem(key + '_' + activeCampaignStr, value);
    }

    const removeFromLocalStorage = (key) => {
        localStorage.removeItem(key + '_' + activeCampaign);
    }


    const startCampaign = (campaignNameHint) => {
        let campaignName = campaignNameHint.replace(/\W/g, '-');
        if (campaignName.length > 10) {
            campaignName = campaignName.substring(0, 25);
        }
        let newCampaigns = campaigns;
        newCampaigns.push(campaignName);
        updateCampaign({'activeCampaign': campaignName, 'campaigns': newCampaigns}, campaignName);
        return campaignName;
    }

    const updateCampaign = (campaignSettings, activeCampaignStr, doCheckCampaignIsReady) => {
        if (typeof activeCampaignStr === typeof undefined) {
            activeCampaignStr = '';
        }
        if (typeof doCheckCampaignIsReady === typeof undefined) {
            doCheckCampaignIsReady = true;
        }
        for (const property in campaignSettings) {
            switch (property) {
                case 'activeCampaign':
                    setActiveCampaign(campaignSettings[property]);
                    localStorage.setItem('campaignActiveCampaign', campaignSettings[property]);
                    break;
                case 'campaigns':
                    setCampaigns([...campaignSettings[property]]);
                    localStorage.setItem('campaignList', JSON.stringify(campaignSettings[property]));
                    break;
                case "targets":
                    let countBusinessTargetsLocal = 0;
                    let countArticleTargetsLocal = 0;
                    for (const target of campaignSettings[property]) {
                        if (target['place_id'] !== undefined) {
                            countBusinessTargetsLocal++;
                        } else {
                            countArticleTargetsLocal++;
                        }
                    }
                    setCountBusinessTargets(countBusinessTargetsLocal);
                    setCountArticleTargets(countArticleTargetsLocal);
                    setTargets([...campaignSettings[property]]);
                    saveLocalStorage('campaignCountBusinessTargets', countBusinessTargetsLocal, activeCampaignStr);
                    saveLocalStorage('campaignCountArticleTargets', countArticleTargetsLocal, activeCampaignStr);
                    saveLocalStorage('campaignTargets', JSON.stringify(campaignSettings[property]), activeCampaignStr);
                    break;
                case "resultsTableType":
                    setResultsTableType(campaignSettings[property]);
                    saveLocalStorage('campaignResultsTableType', campaignSettings[property], activeCampaignStr);
                    break;
                case "searchTargetsResults":
                    setSearchTargetsResults([...campaignSettings[property]]);
                    saveLocalStorage('campaignSearchTargetsResults', JSON.stringify(campaignSettings[property]), activeCampaignStr);
                    break;
                case "ads":
                    setAds([...campaignSettings[property]]);
                    saveLocalStorage('campaignAds', JSON.stringify(campaignSettings[property]), activeCampaignStr);
                    break;
                case "campaignId":
                    setCampaignId(campaignSettings[property]);
                    saveLocalStorage('campaignId', campaignSettings[property], activeCampaignStr);
                    break;
                case "landingPage":
                    setLandingPage(campaignSettings[property]);
                    saveLocalStorage('campaignLandingPage', campaignSettings[property], activeCampaignStr);
                    break;
                case "budget":
                    setBudget(campaignSettings[property]);
                    saveLocalStorage('campaignBudget', campaignSettings[property], activeCampaignStr);
                    break;
                case "budgetId":
                    setBudgetId(campaignSettings[property]);
                    if (campaignSettings[property] === "9999") {
                        removeFromLocalStorage('campaignBudgetId');
                    } else {
                        saveLocalStorage('campaignBudgetId', campaignSettings[property], activeCampaignStr);
                    }
                    break;
                case "budgetType":
                    setBudgetType(campaignSettings[property]);
                    saveLocalStorage('campaignBudgetType', campaignSettings[property], activeCampaignStr);
                    break;
                case "conversionEvent":
                    setConversionEvent(campaignSettings[property]);
                    saveLocalStorage('campaignConversionEvent', campaignSettings[property], activeCampaignStr);
                    break;
                case "conversionTracking":
                    setConversionTracking(campaignSettings[property]);
                    saveLocalStorage('campaignConversionTracking', campaignSettings[property], activeCampaignStr);
                    break;
                case "startDate":
                    setStartDate(campaignSettings[property]);
                    saveLocalStorage('campaignStartDate', JSON.stringify(campaignSettings[property]), activeCampaignStr);
                    break;
                case "endDate":
                    setEndDate(campaignSettings[property]);
                    saveLocalStorage('campaignEndDate', JSON.stringify(campaignSettings[property]), activeCampaignStr);
                    break;
                case "isContinuous":
                    setIsContinuous(campaignSettings[property]);
                    saveLocalStorage('campaignIsContinuous', campaignSettings[property], activeCampaignStr);
                    break;
                case "insertionOrderId":
                    setInsertionOrderId(campaignSettings[property]);
                    saveLocalStorage('campaignInsertionOrderId', campaignSettings[property], activeCampaignStr);
                    break;
                case "universalPixel":
                    setUniversalPixel(campaignSettings[property]);
                    saveLocalStorage('campaignUniversalPixel', JSON.stringify(campaignSettings[property]), activeCampaignStr);
                    break;
                /*case "S3Directory":
                    setS3Directory(campaignSettings[property]);
                    localStorage.setItem('campaignS3Directory', campaignSettings[property]);
                    break;*/
                default:
                    break;
            }
        }
        if (campaignSettings['budget'] !== undefined && campaignSettings['targets'] !== undefined && doCheckCampaignIsReady) {
            let isReady = checkCampaignIsReady(campaignSettings['budget'], campaignSettings['targets']);
            setCampaignIsReady(isReady);
            return;
        }
        if (campaignSettings['budget'] !== undefined && doCheckCampaignIsReady) {
            let isReady = checkCampaignIsReady(campaignSettings['budget']);
            setCampaignIsReady(isReady);
            return;
        }
        if (campaignSettings['targets'] !== undefined && doCheckCampaignIsReady) {
            let isReady = checkCampaignIsReady(undefined, campaignSettings['targets']);
            setCampaignIsReady(isReady);
            return;
        }
        if (doCheckCampaignIsReady) {
            let isReady = checkCampaignIsReady();
            setCampaignIsReady(isReady);
        }
    }

    const getTotalBudget = () => {
        let thisTotalBudget = 0;
        let totalBudgetCalculated = 0;
        for (let target of targets) {
            if (target['budget'] !== undefined && isNumeric(target['budget'])) {
                totalBudgetCalculated += parseFloat(target['budget']);
            } else {
                totalBudgetCalculated += (budget / targets.length);
            }
        }
        if (budgetType === 'day') {
            thisTotalBudget = (totalBudgetCalculated * 7).toFixed(2);

        } else {
            thisTotalBudget = totalBudgetCalculated.toFixed(2);
        }
        return thisTotalBudget;
    }

    const getTotalToPay = () => {
        if (!isContinuous) {
            return budget;
        }
        let daysInMonthStartDate = new Date(startDate.getYear(), (startDate.getMonth() + 1), 0).getDate();

        let remainingDaysInMonth = (daysInMonthStartDate - startDate.getDate()) + 1;
        return ((budget / daysInMonthStartDate) * remainingDaysInMonth).toFixed(2);
    }


    const calculateAssignedBudget = (targetKeyId, targetKeyValue, globalBudget) => {
        if (typeof globalBudget === typeof undefined) {
            globalBudget = budget;
        }
        let assignedBudgetCalculate = 0;
        for (const [targetId, target] of targets.entries()) {
            if (typeof targetKeyId !== typeof undefined && typeof targetKeyValue !== typeof undefined && targetId === targetKeyId) {
                if (targetKeyValue !== '' && !isNaN(targetKeyValue)) {
                    assignedBudgetCalculate += parseFloat(targetKeyValue);
                }

            } else {
                if (target['budget'] !== undefined) {
                    if (target['budget'] !== '') {
                        assignedBudgetCalculate += parseFloat(target['budget']);
                    }
                } else {
                    assignedBudgetCalculate += parseFloat((parseFloat(globalBudget) / targets.length).toFixed(2));
                }
            }
        }
        setAssignedBudget(assignedBudgetCalculate);
        setRemainingBudget(globalBudget - assignedBudgetCalculate);
    }

    const assignBudgetToTargets = (budget) => {
        let sumAssignedBudget = 0;
        for (const [targetId, target] of targets.entries()) {
            if (target['budget'] === undefined || !isNumeric(target['budget'])) {
                //updateTargetProperty(targetId, 'budget', (budget / targets.length));
                sumAssignedBudget += (budget / targets.length);
            } else {
                sumAssignedBudget += target['budget'];
            }
        }
        setAssignedBudget(sumAssignedBudget);
    }

    const defaultCampaign = {
        /*searchBusinessKeywordTarget,
        setSearchBusinessKeywordTarget,
        searchArticlesKeywordTarget,
        setSearchArticlesKeywordTarget,*/
        targets,
        countArticleTargets,
        countBusinessTargets,
        searchTargetsResults,
        resultsTableType,
        ads,
        campaignId,
        landingPage,
        budget,
        budgetType,
        conversionEvent,
        conversionTracking,
        startDate,
        endDate,
        isContinuous,
        updateCampaign,
        updateAdUrl,
        addToAds,
        removeFromAds,
        removeFromTargets,
        updateTargetProperty,
        updateAdProperty,
        cleanCampaign,
        adsHaveValidLandingPages,
        adsHaveValidAdSizes,
        resetBudget,
        setResetBudget,
        resetDates,
        setResetDates,
        removeAllAdsFromTargets,
        assignAllAdsToTargets,
        campaignIsReady,
        checkIsValidUrl,
        getTotalBudget,
        assignedBudget,
        setAssignedBudget,
        calculateAssignedBudget,
        assignBudgetToTargets,
        getTotalToPay,
        remainingBudget,
        setRemainingBudget,
        budgetId,
        universalPixel,
        insertionOrderId,
        campaigns,
        activeCampaign,
        startCampaign,
        updateAdDimensions
    }

    return (
        <CampaignContext.Provider value={defaultCampaign}>
            {children}
        </CampaignContext.Provider>
    );
}

export {CampaignContext, CampaignProvider};