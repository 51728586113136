import React from "react";

function NotificationIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_287_597)">
        <path
          d="M15.5 10H14.5V6.5C14.5 4.49155 12.6517 2.76142 10.8319 2.18408C10.7975 0.974034 9.80316 0 8.58492 0H8.54677C7.34198 0 6.35565 0.952574 6.30087 2.14405C4.41289 2.67896 2.5 4.44299 2.5 6.5V10H1.5V14L6.23818 14.071C6.41212 15.1628 7.36002 16 8.49999 16C9.63997 16 10.5879 15.1628 10.7618 14.071L15.5 14V10ZM8.54677 0.723364H8.58492C9.3473 0.723364 9.98082 1.28561 10.0926 2.01722C9.89021 1.98925 9.68361 1.97445 9.47364 1.97445H7.5263C7.36272 1.97445 7.20117 1.98347 7.04208 2.00058C7.1609 1.27717 7.79023 0.723364 8.54677 0.723364ZM8.49999 15.2766C7.76028 15.2766 7.13906 14.7613 6.97529 14.071H10.0247C9.86093 14.7613 9.23971 15.2766 8.49999 15.2766ZM14.5 13H2.5V11H3.5V6.5C3.5 4.41724 6 3 7.5263 3H9.5C11 3 13.5 4.41724 13.5 6.5V11H14.5V13Z"
          fill="#131B23"
        />
      </g>
      <defs>
        <clipPath id="clip0_287_597">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NotificationIcon;
