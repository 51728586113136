import React from 'react';
import axios from "axios";
import {Auth} from "aws-amplify";
import {apiException} from "../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../data/Urls";

const GetInsertionOrders = async (advertiserId) => {
    let insertionOrdersResponse = {
        status: false,
        error: '',
        info: {}
    }
    const currentUserInfo = await Auth.currentUserInfo();
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const request = {'advertiserId': advertiserId};
    await axios.get(getCatalyticsApiUrl() + 'insertion-orders',
        {
            params: {
                advertiserId: advertiserId
            },
            headers: {
                'Authorization': `Basic ${token}`
            }
        }).then(async (response) => {
        if (response['data'] === null) {
            insertionOrdersResponse.error = 'Empty response from GET insertion orders';
            apiException(insertionOrdersResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, response);
            return;
        }
        let body = JSON.parse(response['data']['body']);
        if (body['response']['status'] === undefined || body['response']['status'] !== 'OK') {
            insertionOrdersResponse.error = 'Invalid response from GET insertion orders';
            apiException(insertionOrdersResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, body);
            return;
        }
        if (body['Error'] === undefined) {
            insertionOrdersResponse.status = true;
            insertionOrdersResponse.info = body['response'];
        }
        //insertionOrdersResponse = await CreateInsertionOrders(advertiserId, name);
    }).catch(function (error) {
        insertionOrdersResponse.error = error;
        apiException(insertionOrdersResponse.error, {'email': currentUserInfo["attributes"]["email"]}, request, {});
    });
    return insertionOrdersResponse;
}


export default GetInsertionOrders;