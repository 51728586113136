import React, {useContext, useEffect, useState} from 'react'//, useContext
import SelectedTargets from "./../../selected-targets/SelectedTargets";
//import {AdvertiserContext} from './AdvertiserContext'
//import TargetContext from './TargetContext'
import {Link} from 'react-router-dom'
import axios from 'axios';
import StepsBar from "../steps-bar/StepsBar";
import './business-search.css'
import {CampaignContext} from "../../../../context/CampaignContext";
import Radiobox from '../../../util/radio-box/Radiobox';
import Checkbox from '../../../util/check-box/Checkbox';
import GoToLink from '../../../../assets/icons/GoToLink';
import getCatalyticsApiUrl from "../../../../data/Urls";

/*import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';*/

const BusinessSearch = (props) => {
    //const [rowData, setRowData] = useState([]);
    //const [selections, setSelections] = useState([]);
    //const targets = useContext(TargetContext)
    const [search, setSearch] = useState("");

    const [location, setLocation] = useState("");
    const [term, setTerm] = useState("");
    const [showMoreButton, setShowMoreButton] = useState(false)
    const [pagination, setPagination] = useState(null);
    const [nextPageLoading, setNextPageLoading] = useState(false);
    //const [showSelectedTargets, setShowSelectedTargets] = useState(false);
    const {
        targets,
        resultsTableType,
        searchTargetsResults,
        updateCampaign,
        cleanCampaign,
        activeCampaign,
        startCampaign
    } = useContext(CampaignContext);
    const [showResultsTable, setShowResultsTable] = useState(() => {
        if (searchTargetsResults.length > 0 && resultsTableType !== undefined) {
            return true;
        }
        return false;
    })
    //const [resultsTableType, setResultsTableType] = useState("search")
    const [loadingLocationSearch, setLoadingLocationSearchSearch] = useState(false)
    const [loadingArticlesSearch, setLoadingArticlesSearch] = useState(false)
    let resultsData = [];

    const [searchTypeSelected, setSearchTypeSelected] = useState(() => {
        if (showResultsTable && resultsTableType === 'term') {
            return 'articles';
        }
        return "business";
    });
    const [offset, setOffset] = useState(0);

    //cleanCampaign();

    useEffect(() => {
        const onScroll = () => {
            setOffset(window.pageYOffset);
        }
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, {passive: true});
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const setTableResults = (newResults, pagination, campaignName) => {
        resultsData = resultsData.concat(newResults);
        updateCampaign({resultsTableType: 'search', searchTargetsResults: resultsData}, campaignName);
        // || resultsData.length > 10
        if (pagination.hasNextPage) {
            setShowMoreButton(true)
        } else {
            setShowMoreButton(false)
        }
        setShowResultsTable(true)

        setLoadingLocationSearchSearch(false);
    }

    const showMoreHandler = () => {
        if (pagination !== null && pagination !== undefined) {
            setNextPageLoading(true);
            pagination.nextPage();

        }
    }

    const getPlaceURL = (placeId) => {
        const google = window.google;
        var service = new google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails({
            placeId: placeId,
            fields: ['website']
        }, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                if (place.website !== undefined && place.website !== null && place.website !== '') {
                    window.open(place.website);
                }
            }
        })
        return '';
    }

    const handleArticlesSubmit = (e) => {
        e.preventDefault();
        setShowMoreButton(false);
        try {
            setLoadingArticlesSearch(true);
            setShowResultsTable(false);
            let campaignName = '';
            if (activeCampaign==''){
                campaignName = startCampaign(term);
            }
            let payload = {"search_term": term};
            axios.post(getCatalyticsApiUrl()+'contextual-target-search', payload)
                .then(function (response) {

                    //console.log(response['data']['body']);
                    let body = JSON.parse(response['data']['body']);
                    //console.log(body['data']);

                    updateCampaign({resultsTableType: 'term', searchTargetsResults: body['data']},campaignName);
                    setLoadingArticlesSearch(false)
                    setShowResultsTable(true)

                })
                .catch(function (error) {
                    setLoadingArticlesSearch(false)
                    console.log(error);
                });
        } catch (error) {
            setLoadingArticlesSearch(false)
            console.log(error)
        }
    }

    const termChangeHandler = (e) => {
        setSearch(e.target.value)
    }

    const locationChangeHandler = (e) => {
        let locationInput = e.target.value.trim()
        if (locationInput.substring(0, 3) === 'in ') {
            setLocation(locationInput.substring(3))
        } else {
            setLocation(locationInput)
        }
    }

    const topicChangeHandler = (e) => {
        let topicInput = e.target.value.trim();
        setTerm(topicInput);
    }

    const handleLocationSubmit = (e) => {
        e.preventDefault();
        setShowMoreButton(false);
        try {
            let campaignName = '';
            setLoadingLocationSearchSearch(true);
            setShowResultsTable(false);
            const google = window.google;
            var service = new google.maps.places.PlacesService(document.createElement('div'));
            let initialize = () => {
                let searchStr = search.trim();
                if (activeCampaign==''){
                    campaignName = startCampaign(searchStr);
                }
                if (searchStr === '') {
                    searchStr = 'places'
                }
                if (location !== '' && searchStr !== '') {
                    searchStr += ' in ' + location;
                }
                var request = {
                    query: searchStr,
                    language: 'en'
                };

                service.textSearch(request, callback);
            }

            let callback = (results, status, pagination) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
//geometry.location
                    setPagination(pagination)
                    setTableResults(results, pagination, campaignName)
                    setNextPageLoading(false);
                }
            }
            return (initialize())
        } catch (error) {
            setLoadingLocationSearchSearch(false)
            console.log(error)
        }
    }

    const updateSelections = (element, type, row) => {
        let found = false;
        let newSelectedRows = []
        targets.forEach((selectedRow) => {
            if (type === 'business-search') {
                if (selectedRow['place_id'] !== undefined && (row['place_id'] === selectedRow['place_id'])) {
                    found = true
                } else {
                    newSelectedRows.push(selectedRow);

                }
            } else {
                if (selectedRow['url'] !== undefined && (row['url'] === selectedRow['url'])) {
                    found = true;
                } else {
                    newSelectedRows.push(selectedRow)
                }
            }

        })
        if (!found) {
            newSelectedRows.push(row)
        }
        updateCampaign({targets: newSelectedRows});

    }

    const searchResultIsChecked = (type, row) => {
        for (let i = 0; i < targets.length; i++) {
            if (type === 'business-search') {
                if (targets[i]['place_id'] !== undefined && (row['place_id'] === targets[i]['place_id'])) {
                    return true;
                }
            } else {
                if (targets[i]['url'] !== undefined && (row['url'] === targets[i]['url'])) {
                    return true;
                }
            }
        }
        return false;
    }


    return (
        <div className="business-search">
            <div className="business-search-container">
                <div className="business-search-form">

                    <div className='step-description'>
                        <h2>SEARCH FOR INDUSTRIES, COMPANIES, OR ARTICLES TO TARGET</h2>
                        <p>Start building your prospect list by searching a business category and location and/or by
                            choosing specific articles where you would like your ads to show.</p>
                    </div>
                    <StepsBar step="1"/>
                    <div className="search-targets-by">
                        <p>Search Targets By:</p>
                        <div className="search-targets-options">
                            <div>
                                {/* <input 
                                    type="radio" 
                                    name="search-targets"
                                    defaultChecked={(!showResultsTable || resultsTableType !== 'term') ? true : false}
                                    onClick={() => setSearchTypeSelected('business')}
                                />  */}
                                <Radiobox
                                    name="search-targets"
                                    defaultChecked={(!showResultsTable || resultsTableType !== 'term') ? true : false}
                                    onClick={() => setSearchTypeSelected('business')}
                                />
                                Business & Location
                            </div>
                            <div>
                                {/* <input type="radio" name="search-targets"
                                        defaultChecked={(showResultsTable && resultsTableType === 'term') ? true : false}
                                        onClick={() => setSearchTypeSelected('articles')}/> Articles */}
                                <Radiobox
                                    name="search-targets"
                                    defaultChecked={(showResultsTable && resultsTableType === 'term') ? true : false}
                                    onClick={() => setSearchTypeSelected('articles')}
                                />
                                Articles
                            </div>
                        </div>
                    </div>
                    {(searchTypeSelected === "business") ? (<form onSubmit={handleLocationSubmit}>
                        <div className="search-form-fields-container">
                            <div className="search-form-field"><input className="input" name="locationQuery"
                                                                      id="businessSearch"
                                                                      value={search} type="text"
                                                                      onChange={(e) => termChangeHandler(e)}
                                                                      placeholder='Business Category or Name'/>
                            </div>

                            <div className="search-form-field"><input className="input" name="location"
                                                                      onChange={(e) => locationChangeHandler(e)}
                                                                      placeholder="Location"/></div>
                            {(!loadingLocationSearch) ? (
                                <div className="button-container">
                                    <button className='button small' type="submit">
                                        Search
                                    </button>
                                </div>) : ''}

                        </div>

                    </form>) : ''}
                    {(searchTypeSelected === "articles") ? (<form onSubmit={handleArticlesSubmit}>
                        <div className="search-form-fields-container">
                            <div className="search-form-field single-form-field "><input className="input" name="topics"
                                                                                         onChange={(e) => topicChangeHandler(e)}
                                                                                         placeholder={"Search for articles by keyword / topic (e.g. \"best CRM software\")"}/>
                            </div>
                            {(!loadingArticlesSearch) ? (
                                <div className="button-container">
                                    <button className='button small' type="submit">
                                        Search
                                    </button>
                                </div>) : ''}
                        </div>


                    </form>) : ''}
                    {(loadingLocationSearch || loadingArticlesSearch) ? (<div className="loader">Loading...</div>) : ''}
                    {(showResultsTable) ? (

                        (resultsTableType === 'search') ? (
                                <div className="search-results">
                                    {/* <h3>Search Results</h3> */}
                                    <table>
                                        <thead>
                                        <tr>
                                            <th className="select">Select</th>
                                            <th className="name">Name</th>
                                            <th className="address">Address</th>
                                            <th className="location">Location</th>
                                            <th className="website">Website</th>
                                            <th className="selected-targets-head">Selected Targets: <b>{targets.length}</b>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {searchTargetsResults?.map((row, key) => {
                                            let isChecked = searchResultIsChecked('business-search', row);
                                            let addressInfo = row['formatted_address'].split(',');
                                            let city = '';
                                            let stateZip = '';
                                            if (addressInfo.length>=2){
                                                city = addressInfo[1].trim();
                                            }
                                            if (addressInfo.length>=3) {
                                                stateZip = addressInfo[2].trim();
                                            }
                                            let state = stateZip.split(' ');
                                            let stateStr = '';
                                            if (state.length>0){
                                                stateStr = state[0];
                                            }
                                            let locationStr = '';
                                            if (city.length>0 && stateStr.length>0){
                                                locationStr = city + ', ' + stateStr;
                                            }
                                            return (
                                                <tr className={isChecked ? ('selected') : ''} key={key}>
                                                    <td className="select">
                                                        <Checkbox
                                                            onChange={(e) => updateSelections(e, 'business-search', row)}
                                                            defaultChecked={isChecked}
                                                        />
                                                    </td>
                                                    <td className="name">{row['name']}</td>
                                                    <td className="address">{row['formatted_address']}</td>
                                                    <td className="location">{locationStr}</td>
                                                    <td className="website">
                                                        <a href="#!" onClick={() => getPlaceURL(row.place_id)}>
                                                            <GoToLink/>
                                                        </a>
                                                    </td>
                                                    {(key === 0) ? (
                                                        <td className="selected-targets-cell"
                                                            rowSpan={searchTargetsResults.length}>
                                                            <div
                                                                className={(offset > 455) ? "selected-targets-container selected-targets-container-business fixed" : "selected-targets-container selected-targets-business-articles"}>
                                                                <SelectedTargets
                                                                    targets={targets}/>
                                                            </div>
                                                        </td>) : <></>}
                                                </tr>)
                                        })}
                                        </tbody>
                                    </table>

                                </div>) :
                            (<div className="search-results">
                                <table className="articles-search-results">
                                    <thead>
                                    <tr>
                                        <th className="select">Select</th>
                                        <th className="site-name">Title</th>
                                        <th>Source</th>
                                        <th className="icon">Link</th>
                                        <th className="selected-targets-head">Selected Targets: <b>{targets.length}</b>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {searchTargetsResults?.map((row, key) => {
                                        let isChecked = searchResultIsChecked('keyword-search', row);
                                        //searchTargetsResults[0]
                                        return (<tr className={isChecked ? ('selected') : ''} key={key}>
                                            <td className="checkbox">
                                                <Checkbox
                                                    onChange={(e) => updateSelections(e, 'keyword-search', row)}
                                                    defaultChecked={isChecked}
                                                />
                                            </td>
                                            <td className="site-name">{row['title']}</td>
                                            <td>{row['inventory_url'].replace('www.', '')}</td>
                                            <td className="icon">
                                                <a href={row['url']} target="_blank" rel="noopener noreferrer">
                                                    <GoToLink/>
                                                </a>
                                            </td>
                                            {(key === 0) ? (
                                                <td className="selected-targets-cell"
                                                    rowSpan={searchTargetsResults.length}>
                                                    <div
                                                        className={(offset > 455) ? "selected-targets-container selected-targets-container-articles fixed" : "selected-targets-container selected-targets-container-articles"}>
                                                        <SelectedTargets
                                                            targets={targets}/>
                                                    </div>
                                                </td>) : <></>}
                                        </tr>)

                                    })}
                                    </tbody>
                                </table>
                            </div>)
                    ) : (loadingLocationSearch || loadingArticlesSearch) ? ('') : (
                        <div className='empty-table'>Your search results will appear here.</div>)}
                    {(showMoreButton) ? (
                        (nextPageLoading)?<div className="loader">Loading...</div>:<div className="show-more-container">
                            <button className="as-link show-more" onClick={() => showMoreHandler()}>show more</button>

                        </div>
                    ) : ''}
                    {(targets !== undefined && targets.length > 0) ? (
                        <div className="step-buttons-container">
                            <div className="step-button-next">
                                <Link
                                    className="button"
                                    to="/create-campaign/1">
                                    Next
                                </Link>
                            </div>
                        </div>
                    ) : ''}
                </div>

            </div>
        </div>
    )

}

/*

 */

export default BusinessSearch;