import React, {useEffect, useState} from 'react';
import {Amplify, Auth} from "aws-amplify";
import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components';
import awsExports from "./../aws-exports";
//import {AppTrials} from "../models";
import CryptoJS from 'crypto-js'
import AppIndex from "./app-index/AppIndex";
import AuthIndex from "./auth/auth-index/AuthIndex";
import PageLoader from "./util/page-loader/PageLoader";
//import {DataStore} from '@aws-amplify/datastore';
import {AccountProvider} from "../context/AccountContext";
import {CampaignProvider} from "../context/CampaignContext";
//import {CampaignContext} from "../context/CampaignContext";
const chmln = require('@chamaeleonidae/chmln');

chmln.init('Snul6U1rnsDexwnwmoUqccoJzeVMFzLhHPSpWD3xwcZGqM-1NudE2-DwlTQNYWKfN19PgI');

Amplify.configure(awsExports)


const App = () => {

    //const [data, setData] = useState({})
    //const [incomingEmail, setIncomingEmail] = useState('')
    const [authState, setAuthState] = useState();
    //const [user, setUser] = useState();
    //const [accountInfo, setAccountInfo] = useState({});
    const [authAlertMessage, setAuthAlertMessage] = useState("");
    //const [step, setStep] = useState(0);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [verifySignUp, setVerifySignUp] = useState(false);
    const [username, setUsername] = useState('');
    const [pass, setPass] = useState('');

    //static contextType = AdvertiserContext;

    function generatePassword() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    /*const siteURL =
        (process.env.REACT_APP_BUILD_ENV === 'production') ? 'https://catalytics.io/' : 'http://localhost:8000/'*/

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            return false;
        }

        return true;
    }


    useEffect(() => {
        let setAccountInfo = (accountInfo) => {
            try {
                localStorage.setItem('accountId', accountInfo['accountId']);
                localStorage.setItem('accountEmail', accountInfo['email']);
                localStorage.setItem('accountType', accountInfo['accountType']);
            } catch (e) {
                console.log(e);
            }
        }

        function updateUser(authDataUser) {
            setAccountInfo({
                accountId: authDataUser['attributes']['custom:Company'],
                email: authDataUser['attributes']['email'],
                accountType: 'user'
            });

            //setUser(authDataUser);
        }

        /*function updateIncomingEmail(email) {
            let emailPieces = email.split('@');
            setAccountInfo({
                accountId: emailPieces[1],
                email: email,
                user: null,
                accountType: 'guest'
            });
            setIncomingEmail(user);
        }*/

        if (authState === undefined) {

            Auth.currentAuthenticatedUser().then(authData => {

                updateUser(authData);
                setAuthState(AuthState.SignedIn);
                setPageLoaded(true);
                return;

            }).catch(e => {
                //let sessionId = '';
                let sessionEmail = '';
                if (window.location.pathname.indexOf('/start-trial') !== -1) {

                    let trialPiecesPath = window.location.pathname.split('/');

                    if (trialPiecesPath.length >= 3) {
                        let sessionEmailEncrypted = decodeURIComponent(trialPiecesPath[2])
                        if (sessionEmailEncrypted.length > 0) {

                            let key = '2e35'
                            sessionEmail = CryptoJS.AES.decrypt(sessionEmailEncrypted, key).toString(CryptoJS.enc.Utf8);
                            if (validateEmail(sessionEmail)) {
                                /*async function fetchTrialInfo(email) {
                                    try {
                                        const appTrialInfo = await DataStore.query(AppTrials, (c) => c.email("eq", email))
                                        if (appTrialInfo !== undefined && appTrialInfo.length > 0) {
                                            sessionId = appTrialInfo[0]['id'];
                                            updateIncomingEmail(sessionEmail);
                                            localStorage.setItem("sessionId", sessionId)
                                            localStorage.setItem("sessionEmail", sessionEmail)
                                            setPageLoaded(true);
                                        } else {
                                            try {
                                                const appTrialInfo = await DataStore.save(
                                                    new AppTrials({
                                                        email: sessionEmail
                                                    })
                                                )
                                                updateIncomingEmail(sessionEmail);
                                            } catch (error) {
                                                console.log(error);
                                            }
                                        }
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }

                                fetchTrialInfo(sessionEmail)*/

                                (async () => {
                                    try {
                                        let pass = generatePassword();
                                        let emailPieces = sessionEmail.split('@');
                                        let company = emailPieces[1];
                                        //let givenName = emailPieces[0];
                                        await Auth.signUp({
                                            username: sessionEmail.toLowerCase(),
                                            password: pass,
                                            attributes: {
                                                email: sessionEmail.toLowerCase(),
                                                'custom:Company': company
                                            }
                                        });

                                        setUsername(sessionEmail);
                                        setPass(pass);
                                        localStorage.setItem('tempKey', CryptoJS.AES.encrypt(pass,key));
                                        setAccountInfo({
                                            accountId: company,
                                            email: sessionEmail,
                                            accountType: 'guest'
                                        });

                                        setVerifySignUp(true);
                                        setPageLoaded(true);


                                    } catch (error) {
                                        if (error.code === "UsernameExistsException") {   //Username already taken
                                            setAuthAlertMessage("The account with email " + sessionEmail + " already exists. Please try to login instead. If you don't have or don't remember your password please try the Reset Password option")
                                        }
                                    }

                                    /* note to use anonymous user just do (but we would be missing the email and company)
                                    const anonymousUser = await Auth.currentCredentials();
                                     */

                                })()

                            }
                        }
                    }
                } /*else {
                    sessionId = localStorage.getItem('sessionId');
                    sessionEmail = localStorage.getItem('sessionEmail');
                    if (sessionEmail !== null && sessionEmail !== undefined && sessionEmail.length > 0) {
                        updateIncomingEmail(sessionEmail);
                        setPageLoaded(true);
                    }
                }*/
            });
        }
        return onAuthUIStateChange((nextAuthState, authData) => {
            if (authData !== undefined && nextAuthState === AuthState.SignedIn) {
                updateUser(authData);
            }
            setAuthState(nextAuthState);

            setPageLoaded(true);
        });


    }, [authState])


    useEffect(() => {
        setTimeout(function () {
            if (!pageLoaded) {
                setPageLoaded(true);
            }
        }, (window.location.pathname.indexOf('/start-trial') !== -1) ? 5000 : 2000)

    }, [pageLoaded]);

    const updateAuthState = (newAuthState) => {
        setAuthState(newAuthState)
    }


    return (!pageLoaded) ? (<PageLoader/>) :
        (authState === AuthState.SignedIn) ? (
                <AccountProvider>
                    <CampaignProvider>
                        <AppIndex/>
                    </CampaignProvider>
                </AccountProvider>) :

            <AuthIndex alertMessage={authAlertMessage} verifySignUp={verifySignUp} user={username} pass={pass}
                           updateAuthState={updateAuthState}/>;


}


export default App;
