import React from "react";

function BuildYourOwn() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_443_1013)">
        <path d="M42 40.0001H2V44.0001H42V40.0001Z" fill="#131B23" />
        <path
          d="M30.68 0L6 24.68V36H17.32L42 11.32L30.68 0ZM15.66 32H10V26.34L30.68 5.65997L36.34 11.3199L15.66 32Z"
          fill="#131B23"
        />
      </g>
      <defs>
        <clipPath id="clip0_443_1013">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BuildYourOwn;
