import React from "react"
import './page-loader.css'

const PageLoader = () => {
    return (<div className="page-loader">
        <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>)
}

export default PageLoader