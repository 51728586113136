import React from 'react';
import axios from "axios";
import {Auth} from "aws-amplify";
import {apiException} from "../../../logging/api/api-exception/apiException";
import getCatalyticsApiUrl from "../../../../../data/Urls";
import stripeProducts from "../../../../../data/StripeProducts";

let subscriptionsResponse = {
    status: false,
    error: '',
    info: {}
};

const GetActiveSubscription = async (customerId) => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const currentUserInfo = await Auth.currentUserInfo();
    await axios.get(getCatalyticsApiUrl()+'stripe-subscriptions', {
            params: {
                "customerId": customerId,
                "env": (process.env.REACT_APP_BUILD_ENV === 'development')?'development':''
            },
            headers: {
                'Authorization': `Basic ${token}`,
                'Content-Type': 'application/json'
            }
        }
    ).then(async (responseGet) => {
        if (responseGet.data === undefined || responseGet.data.body === undefined) {
            subscriptionsResponse.error = 'Empty response from GET subscription';
            apiException(subscriptionsResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},responseGet);
            return;
        }
        let body = JSON.parse(responseGet['data']['body']);
        if (body['subscriptionInfo'] === undefined) {
            subscriptionsResponse.error = 'Subscriptions info not returned from GET subscription';
            apiException(subscriptionsResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},responseGet);
            return;
        }
        if (body['subscriptionInfo']['data'] === undefined) {
            subscriptionsResponse.status = true;
            subscriptionsResponse.info = {};
            return;
        }
        if (body['subscriptionInfo']['data'].length===0){
            subscriptionsResponse.status = true;
            subscriptionsResponse.info = {};
            return;
        }
        let lastSubscription = {};
        let lastSubscriptionTime = 0;
        for (let subscription of body['subscriptionInfo']['data'] ) {
            if (subscription.created > lastSubscriptionTime) {
                lastSubscriptionTime = subscription.created;
                lastSubscription = subscription;
            }
        }
        let stripeProductId = 0;
        for (const priceSubscription of stripeProducts()) {
            if (lastSubscription['plan']['id'] === priceSubscription['priceId']) {
                lastSubscription['planInfo'] = priceSubscription;
                lastSubscription['stripeProductId']=stripeProductId;
            }
            stripeProductId++;
        }
        subscriptionsResponse.status = true;
        subscriptionsResponse.info = lastSubscription;


    }).catch(function (error) {
        subscriptionsResponse.error = error;
        apiException(subscriptionsResponse.error,{'email': currentUserInfo["attributes"]["email"]},{},{});
    });
    return subscriptionsResponse;
}

export default GetActiveSubscription;