import React, {useContext, useEffect, useState} from "react";
import stripeProducts from '../../../../data/StripeProducts';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StepsBar from "../steps-bar/StepsBar";
import './pay-campaign.css';
import {Auth} from "aws-amplify";
import CryptoJS from "crypto-js";
import {CampaignContext} from "../../../../context/CampaignContext";
import Payment from "../../../util/payment/Payment";
import CreateCustomer from "../../../../api/services/stripe/customer/create-customer/CreateCustomer";
import CreateSubscription from "../../../../api/services/stripe/subscription/create-subscription/CreateSubscription";
import CreatePaymentIntent
    from "../../../../api/services/stripe/payment-intent/create-payment-intent/CreatePaymentIntent";
import GetActiveSubscription
    from "../../../../api/services/stripe/subscription/get-active-subscription/GetActiveSubscription";

const stripePromise = (process.env.REACT_APP_BUILD_ENV === 'development') ? loadStripe('pk_test_CzBmMYtI0nIQypgPgfSNiuDO00zXgE1LHM') : loadStripe('pk_live_egCkfexZW7gS9EiDfDykKr2v00ozDjv5SA');

const PayCampaign = () => {

    let stripeCustomerIdStoredStr = (process.env.REACT_APP_BUILD_ENV === 'development') ? 'custom:StripeCustomerId' : 'custom:LiveStripeCustomerId';

    const {
        isContinuous,
        getTotalBudget,
        //getTotalToPay,
        budget,
        //budgetId,
        budgetType,
        startDate,
        endDate
    } = useContext(CampaignContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [password, setPassword] = useState('');
    const [clientSecretLoaded, setClientSecretLoaded] = useState(false);
    const [options, setOptions] = useState({clientSecret: '{{CLIENT_SECRET}}'});
    const [alertField, setAlertField] = useState('');
    const [loading, setLoading] = useState(false);
    const [nextBilling, setNextBilling] = useState(0);
    const [givenName, setGivenName] = useState('');
    const [totalBudget, setTotalBudget] = useState(0.0);
    const [stripeCustomerId, setStripeCustomerId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [totalToPay, setTotalToPay] = useState(0);
    //const [totalNextBilling, setTotalNextBilling] = useState(0);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(-1);
    const [contactUsLimitReached, setContactUsLimitReached] = useState(false);
    const [showSubscription, setShowSubscription] = useState(false);
    const [currentSubscription, setCurrentSubscription] = useState({});
    const [changingSubscription, setChangingSubscription] = useState(false);
    const [terms, setTerms] = useState(false);
    const [showAlertTerms, setShowAlertTerms] = useState(false);


    const monthlyBudgetLimit = 10000;

    const appearance = {
        theme: 'flat',
        variables: {
            fontFamily: ' "Gill Sans", sans-serif',
            fontLineHeight: '1.5',
            borderRadius: '10px',
            colorBackground: '#F6F8FA',
            colorPrimaryText: '#000000'
        },
        rules: {
            '.Block': {
                backgroundColor: 'var(--colorBackground)',
                boxShadow: 'none',
                padding: '12px'
            },
            '.Input': {
                padding: '12px',
                backgroundColor: '#ECECF0',
                border: '1px solid #FFFFFF',
                borderRadius: '0px'
            },
            '.Input:disabled, .Input--invalid:disabled': {
                color: 'lightgray'
            },
            '.Tab': {
                padding: '10px 12px 8px 12px',
                border: 'none'
            },
            '.Tab:hover': {
                border: 'none',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
                border: 'none',
                backgroundColor: '#fff',
                boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
            },
            '.Label': {
                fontWeight: '500'
            }
        }
    };

    const getPriceSubscriptionId = async (currentUserInfo) => {
        let monthlyBudget = budget;
        if (budgetType === 'day') {
            monthlyBudget = budget * 30;
        }
        if (monthlyBudget > monthlyBudgetLimit) {
            setContactUsLimitReached(true);
            return -999;
        }
        let monthlyBudgetToCalculate = monthlyBudget;
        let activeSubscriptionResponse = {};
        if (currentUserInfo["attributes"][stripeCustomerIdStoredStr] !== undefined && currentUserInfo["attributes"][stripeCustomerIdStoredStr] !== '') {
            activeSubscriptionResponse = await GetActiveSubscription(currentUserInfo["attributes"][stripeCustomerIdStoredStr]);
            if (!activeSubscriptionResponse.status) {
                console.log(activeSubscriptionResponse.error);
            }

            if (activeSubscriptionResponse['info'] !== undefined && activeSubscriptionResponse['info']['plan'] !== undefined) {
                setCurrentSubscription(activeSubscriptionResponse['info']);
                monthlyBudgetToCalculate = (monthlyBudget + activeSubscriptionResponse['info']['planInfo']['price']);
                if (monthlyBudgetToCalculate > monthlyBudgetLimit) {
                    setContactUsLimitReached(true);
                    return -999;
                }

            }
        }
        let counter = 0;
        let bestPriceSubscriptionId = 0;
        for (const priceSubscription of stripeProducts()) {
            if (priceSubscription['price'] > monthlyBudgetToCalculate) {
                bestPriceSubscriptionId = counter;
                break;
            }
            counter++;
        }
        if (activeSubscriptionResponse['info'] !== undefined && activeSubscriptionResponse['info']['plan'] !== undefined) {
            if (bestPriceSubscriptionId != activeSubscriptionResponse['info']['planInfo']['priceId'] != stripeProducts()[bestPriceSubscriptionId]['priceId']) {
                setChangingSubscription(true);
            }
        }
        return bestPriceSubscriptionId;
    }

    const createThisSubscription = async (priceSubscriptionId, stripeCustomerId) => {
        setClientSecretLoaded(false);
        const stripeSubscriptionResponse = await CreateSubscription(priceSubscriptionId, stripeCustomerId);
        if (!stripeSubscriptionResponse.status) {
            console.log(stripeSubscriptionResponse.error);
            return;
        }
        let subscriptionId = stripeSubscriptionResponse.info['subscriptionId'];
        let clientSecretId = stripeSubscriptionResponse.info['clientSecret'];
        setClientSecret(clientSecretId);
        setOptions({
            /*fonts: [{
                cssSrc: 'https://fonts.googleapis.com/css?family=Rajdhani:400,500'
            }],*/
            appearance: appearance,
            clientSecret: clientSecretId
        });
        setClientSecretLoaded(true);
    }

    useEffect(() => {
        (async () => {
            /*console.log('UPDATE CUSTOMER ID');
            console.log(stripeCustomerIdStoredStr);
            let user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {
                [stripeCustomerIdStoredStr]: ''
            });
            return;*/
            let thisTotalBudget = getTotalBudget();
            setTotalBudget(thisTotalBudget);

            let thisTotalToPay = budget;
            let thisPriceSubscriptionId = 0;
            let currentUserInfo = await Auth.currentUserInfo();
            if (!isContinuous) {
                if (budgetType === 'day') {
                    var differenceInTime = endDate.getTime() - startDate.getTime();
                    let numberOfDays = differenceInTime / (1000 * 3600 * 24);
                    thisTotalToPay = (budget * numberOfDays).toFixed(2);
                }
                if (thisTotalToPay > monthlyBudgetLimit) {
                    setContactUsLimitReached(true);
                    return;
                }
            } else {
                thisPriceSubscriptionId = await getPriceSubscriptionId(currentUserInfo);
                setShowSubscription(true);
                if (thisPriceSubscriptionId === -999) {
                    return;
                }
                setSelectedSubscriptionId(thisPriceSubscriptionId);
                thisTotalToPay = stripeProducts()[thisPriceSubscriptionId]['price'];
            }
            setTotalToPay(thisTotalToPay);
            if (isContinuous) {
                setNextBilling(thisTotalToPay);
            }

            if (currentUserInfo['attributes'] !== undefined && currentUserInfo["attributes"]["given_name"] !== undefined) {
                setGivenName(currentUserInfo["attributes"]["given_name"]);
            }
            if (currentUserInfo['attributes'] === undefined || currentUserInfo['attributes'][stripeCustomerIdStoredStr] === undefined || currentUserInfo['attributes'][stripeCustomerIdStoredStr] === '') {
                const stripeCustomerResponse = await CreateCustomer();
                if (!stripeCustomerResponse.status) {
                    console.log(stripeCustomerResponse.error);
                    return;
                }
                let stripeCustomerId = stripeCustomerResponse.info;
                let user = await Auth.currentAuthenticatedUser();
                await Auth.updateUserAttributes(user, {
                    [stripeCustomerIdStoredStr]: stripeCustomerId
                });
                setStripeCustomerId(stripeCustomerId);
                if (isContinuous) {
                    await createThisSubscription(thisPriceSubscriptionId, stripeCustomerId);
                }
            } else {
                let stripeCustomerId = currentUserInfo['attributes'][stripeCustomerIdStoredStr];
                setStripeCustomerId(stripeCustomerId);
                if (isContinuous) {
                    await createThisSubscription(thisPriceSubscriptionId, stripeCustomerId);
                }
            }

            if (!isContinuous) {
                const paymentIntentResponse = await CreatePaymentIntent(thisTotalToPay * 100);
                if (!paymentIntentResponse.status) {
                    console.log(paymentIntentResponse.error);
                    return;
                }
                let clientSecretId = paymentIntentResponse.info;
                setClientSecret(clientSecretId);
                setOptions({
                    appearance: appearance,
                    clientSecret: clientSecretId
                });
                setClientSecretLoaded(true);
            }
        })()

    }, []);

    const termsChangeHandler = () => {
        setTerms(!terms);
    }

    const updateAccountDetails = async () => {
        setShowAlertTerms(false);
        if (firstName === '') {
            setAlertField('firstName');
            return;
        }
        if (lastName === '') {
            setAlertField('lastName');
            return;
        }
        if (companyName === '') {
            setAlertField('companyName');
            return;
        }
        if (password === '') {
            setAlertField('password');
            return;
        }
        if (!terms) {
            setShowAlertTerms(true);
            return;
        }
        setLoading(true);
        let key = '2e35'
        let oldPass = CryptoJS.AES.decrypt(localStorage.getItem('tempKey'), key).toString(CryptoJS.enc.Utf8);
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPass, password);
            });
        let user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            'given_name': firstName,
            'family_name': lastName,
            'custom:Company': companyName

        });
        setGivenName(firstName);
        setLoading(false);
    }

    const changeSubscriptionId = async (id) => {
        setSelectedSubscriptionId(id);
        let thisTotalToPay = stripeProducts()[id]['price'];
        setTotalToPay(thisTotalToPay);
        setNextBilling(thisTotalToPay);
        await createThisSubscription(id, stripeCustomerId);
    }

    return (

        (contactUsLimitReached) ?
            <div className="contact-us-alert">
                <p>Your Monthly Budget is above $10,000.&nbsp;&nbsp;
                    <a href="">Please contact us to
                        continue</a>.</p>
                <p><a href="/create-campaign/2">Back</a></p>
            </div> :
            (clientSecretLoaded && options.clientSecret !== '{{CLIENT_SECRET}}') ?
                <Elements stripe={stripePromise} options={options}>
                    <div className="pay-campaign">
                        <StepsBar step="4"/>
                        <div className="form-split">
                            {(givenName !== '') ?
                                <div className="half payment-form">
                                    <div className="half-padding">
                                        {(parseInt(totalBudget) > 1 && stripeCustomerId !== '') ? (<Payment
                                            totalToPay={totalToPay}
                                            nextBilling={nextBilling}
                                            stripeCustomerId={stripeCustomerId}
                                            subscriptionId={selectedSubscriptionId}
                                            subscriptionIdChangeHandler={changeSubscriptionId}
                                            showSubscription={showSubscription}
                                            changingSubscription={changingSubscription}
                                            currentSubscription={currentSubscription}
                                        />) : ''}
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="half account-form">
                                        <div className="account-info-section">
                                            <h3>Create Account</h3>
                                            <div className="subtitle">Set up your Catalytics account so we can get
                                                started!
                                            </div>
                                            <div className="form-container">
                                                <div className="field">
                                                    <label>First Name</label>
                                                    <input type="text" name="first-name"
                                                           className={(alertField === 'firstName') ? "alert" : ''}
                                                           onChange={(e) => setFirstName(e.target.value)} required/>
                                                </div>
                                                <div className="field">
                                                    <label>Last Name</label>
                                                    <input type="text" name="first-name"
                                                           className={(alertField === 'lastName') ? "alert" : ''}
                                                           onChange={(e) => setLastName(e.target.value)}
                                                           required/>
                                                </div>
                                                <div className="field">
                                                    <label>Company Name</label>
                                                    <input type="text" name="company-name"
                                                           className={(alertField === 'companyName') ? "alert" : ''}
                                                           onChange={(e) => setCompanyName(e.target.value)} required/>
                                                </div>
                                                <div className="field">
                                                    <label>Password</label>
                                                    <input type="password" name="password"
                                                           className={(alertField === 'password') ? "alert" : ''}
                                                           onChange={(e) => setPassword(e.target.value)}
                                                           required/>
                                                </div>
                                                <div className="field">
                                                    <input name="terms" type="checkbox" className="checkbox" checked={terms}
                                                           onChange={termsChangeHandler}/> I agree to the <a
                                                    href="https://catalytics.io/resources/terms-of-use.html" target="_blank">Catalytics Terms of Use</a>

                                                </div>
                                                {(!terms && showAlertTerms) ? (<div className="accept-terms-alert">Please accept the Catalytics Terms of Use</div>) : ''}
                                            </div>
                                            <div className="create-account-button-container">

                                                {(loading) ? (<div className="loader">Loading...</div>) : (
                                                    <button className="button secondary"
                                                            onClick={async () => await updateAccountDetails()}>Submit</button>)}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Elements> :
                <div className="loader">Loading...</div>

    )
        ;
}

export default PayCampaign;
