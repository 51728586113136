import React from "react";

function Custom() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5 27.5H7.18555L27.5 2.1069V16.5H36.8145L16.5 41.8931V27.5ZM14.8145 23.8333H20.1667V31.4402L29.1855 20.1667H23.8334V12.5598L14.8145 23.8333Z"
        fill="#131B23"
      />
    </svg>
  );
}

export default Custom;
