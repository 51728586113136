import React, { useState } from 'react';
import Checkbox from '../../util/check-box/Checkbox';

import { ReactComponent as GroupsActive } from '../../../assets/images/ActiveColumns.svg';
import { ReactComponent as Groups } from '../../../assets/images/ActiveGroup.svg';
import './filter.css';

export default function ColumnFilter({ headers, gridRef }) {
    const [showGroups, setShowGroups] = useState(false);

    function headerChecked(headerId) {
        if (gridRef) {
            let colState = gridRef.current.columnApi.getColumnState();
            return !colState.find((c) => c.colId === headerId).hide || true;
        } else return true;
    }

    function toggleHeader(headerId) {
        let colState = gridRef.current.columnApi.getColumnState();
        let current = [...colState].find((c) => c.colId === headerId);

        if (current) {
            current.hide = !current.hide;
        }

        let newState = colState.reduce((c, p) => {
            return [...c, p.colId === headerId ? current : p];
        }, []);

        gridRef.current.columnApi.applyColumnState({
            state: newState,
            applyOrder: true,
        });
    }

    function headerChecked(headerId) {
        if (gridRef) {
            let colState = gridRef.current.columnApi.getColumnState();
            return !colState.find((c) => c.colId === headerId).hide || true;
        } else return true;
    }
    return (
        <>
            <button
                className={showGroups ? 'active-group-button' : 'group-button'}
                onClick={() => setShowGroups(!showGroups)}
            >
                {showGroups ? <GroupsActive /> : <Groups />}
                <span className="group-column-text">Columns</span>
            </button>
            <div className="column-menu">
                {showGroups &&
                    headers.map((d) => (
                        <div className="column-menu-item" key={d.field}>
                            <Checkbox
                                defaultChecked={headerChecked(d.field)}
                                onChange={(e) => toggleHeader(d.field)}
                            />
                            <div style={{ paddingLeft: 10 }}>
                                {d.headerName}
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
}
