import React, {useState} from "react";
import {Auth} from "aws-amplify";
import freeEmailDomains from '../../../data/FreeEmailDomains'
import './auth-components.css';

const AuthComponents = (props) => {
    const [authView, setAuthView] = useState('login');
    const [fieldAlert, setFieldAlert] = useState('');
    const [authErrorAlert, setAuthErrorAlert] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [password, setPassword] = useState('');
    const [terms, setTerms] = useState(false);
    const [showAlertTerms, setShowAlertTerms] = useState(false);
    const [validationCode, setValidationCode] = useState('');
    const [loading, setLoading] = useState(false);

    const changeAuthView = (authView) => {
        setAuthErrorAlert('');
        setFieldAlert('');
        if (authView === 'reset') {
            setPassword('');
        }
        setAuthView(authView);
    }

    const emailChangeHandler = (e) => {
        setAuthErrorAlert('');
        setFieldAlert('');
        setEmail(e.target.value);
    }

    const firstNameChangeHandler = (e) => {
        setAuthErrorAlert('');
        setFieldAlert('');
        setFirstName(e.target.value);
    }

    const lastNameChangeHandler = (e) => {
        setAuthErrorAlert('');
        setFieldAlert('');
        setLastName(e.target.value);
    }

    const companyNameChangeHandler = (e) => {
        setAuthErrorAlert('');
        setFieldAlert('');
        setCompanyName(e.target.value);
    }

    const passwordChangeHandler = (e) => {
        setAuthErrorAlert('');
        setFieldAlert('');
        setPassword(e.target.value);
    }

    const termsChangeHandler = () => {
        setTerms(!terms);
    }

    const validationCodeChangeHandler = (e) => {
        setAuthErrorAlert('');
        setFieldAlert('');
        setValidationCode(e.target.value);
    }

    const tryLogin = async () => {
        if (email === '') {
            setFieldAlert('email');
            return;
        }
        if (password === '') {
            setFieldAlert('password');
            return;
        }
        try {
            setLoading(true);
            await Auth.signIn(email, password).then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    Auth.completeNewPassword(user, password)
                        .then(user => {
                        });
                }
            });
            props.setSignedIn();
        } catch (error) {
            console.log('error signing in', error);
            setAuthErrorAlert("The combination of login and password failed");
            setLoading(false);

        }
    }

    const isCorporateEmail = (email) => {
        let domainPieces = email.split('@');
        let domain = domainPieces[1];
        let freeEmailDomainsList = freeEmailDomains();
        if (freeEmailDomainsList.includes(domain)) {
            return false;
        }
        return true;
    }

    const trySignUp = async () => {
        setShowAlertTerms(false);
        if (email === '') {
            setFieldAlert('email');
            return;
        }
        if (!isCorporateEmail(email)) {
            setFieldAlert('email');
            setAuthErrorAlert("Please use your company email");
            return;
        }
        if (firstName === '') {
            setFieldAlert('firstName');
            return;
        }
        if (lastName === '') {
            setFieldAlert('lastName');
            return;
        }
        if (companyName === '') {
            setFieldAlert('companyName');
            return;
        }
        if (password === '') {
            setFieldAlert('password');
            return;
        }
        if (!terms) {
            setShowAlertTerms(true);
            return;
        }
        try {
            setLoading(true);
            await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email: email,
                    given_name: firstName,
                    family_name: lastName,
                    'custom:Company': companyName
                }
            });
            changeAuthView('confirm-signup');
            setLoading(false);
            //props.setSignedIn();
        } catch (error) {
            console.log('error signing in', error);
            if (error.toString().indexOf('An account with the given email already exists') > -1) {
                setAuthErrorAlert("The account already exists. Please try to login instead");
            } else if (error.toString().indexOf("Password does not conform to policy") > -1) {
                setAuthErrorAlert("Weak Password. Please use a password of at least 8 characters, with numbers and lower and uppercase letters");
            } else {
                setAuthErrorAlert("The signup could not be completed");
            }

            setLoading(false);

        }
    }

    const tryReset = async () => {
        if (validationCode === '') {
            setFieldAlert('validationCode');
            return;
        }
        if (password === '') {
            setFieldAlert('password');
            return;
        }
        try {
            setLoading(true);

            await Auth.forgotPasswordSubmit(email, validationCode, password);
            await Auth.signIn(email, password);
            props.setSignedIn();
            setLoading(false);
        } catch (error) {
            console.log('error confirming signup', error);
            if (error.toString().indexOf("Password does not conform to policy") > -1) {
                setAuthErrorAlert("Weak Password. Please use a password of at least 8 characters, with numbers and lower and uppercase letters");
            } else {
                setAuthErrorAlert("Invalid code");
            }

            setLoading(false);
        }
    }

    const tryConfirmSignUp = async () => {
        if (validationCode === '') {
            setFieldAlert('validationCode');
            return;
        }
        try {
            setLoading(true);
            await Auth.confirmSignUp(email, validationCode);
            await Auth.signIn(email, password);
            props.setSignedIn();
            setLoading(false);
        } catch (error) {
            console.log('error confirming signup', error);
            setAuthErrorAlert("Invalid code");
            setLoading(false);
        }
    }

    const tryForgotPassword = async () => {
        if (email === '') {
            setFieldAlert('email');
            setAuthErrorAlert("Please enter your email address");
            return;
        }
        try {
            setLoading(true);
            await Auth.forgotPassword(email);
            changeAuthView('reset');
            setLoading(false);
        } catch (error) {
            console.log('error sending confirmation code', error);
            setAuthErrorAlert("The account does not exist");
            setLoading(false);
        }
    }


    switch (authView) {
        case 'login':
        default:
            return (<div className="auth-container login-container">
                <h3>Welcome Back</h3>
                {(authErrorAlert !== '') ? (<div className="auth-error-alert">{authErrorAlert}</div>) : ''}
                <div className="auth-field">
                    <label>Email</label>
                    <input type="email" name="email" className={(fieldAlert === 'email') ? ("auth-alert") : ""}
                           onChange={(e) => emailChangeHandler(e)}/>
                </div>
                <div className="auth-field">
                    <label>Password</label>
                    <input type="password" name="password" className={(fieldAlert === 'password') ? ("auth-alert") : ""}
                           onChange={(e) => passwordChangeHandler(e)}/>
                </div>
                <div className="forgot-password">
                    <a href="#!" onClick={() => tryForgotPassword()}>Forgot password?</a>
                </div>
                <div className="button-container">
                    {(loading) ? (<div className="loader">Loading...</div>) : (
                        <button onClick={() => tryLogin()}>Sign In</button>)}
                </div>
                <div className="create-account-container">
                    <p>Do not have an account yet? <a href="#!" onClick={() => changeAuthView('signup')}>Sign up</a></p>
                </div>
            </div>);
        case 'signup':
            return (<div className="auth-container signup-container">
                <h3>Account Setup</h3>
                {(authErrorAlert !== '') ? (<div className="auth-error-alert">{authErrorAlert}</div>) : ''}
                <div className="auth-field">
                    <input name="email" type="email" placeholder="Company Email"
                           className={(fieldAlert === 'email') ? ("auth-alert") : ""}
                           onChange={(e) => emailChangeHandler(e)}/>
                </div>
                <div className="auth-field">
                    <input name="first-name" type="text" placeholder="First Name"
                           className={(fieldAlert === 'firstName') ? ("auth-alert") : ""}
                           onChange={(e) => firstNameChangeHandler(e)}/>
                </div>
                <div className="auth-field">
                    <input name="last-name" type="text" placeholder="Last Name"
                           className={(fieldAlert === 'lastName') ? ("auth-alert") : ""}
                           onChange={(e) => lastNameChangeHandler(e)}/>
                </div>
                <div className="auth-field">
                    <input name="company-name" type="text" placeholder="Company Name"
                           className={(fieldAlert === 'companyName') ? ("auth-alert") : ""}
                           onChange={(e) => companyNameChangeHandler(e)}/>
                </div>
                <div className="auth-field">
                    <input name="password" type="password" placeholder="Password"
                           className={(fieldAlert === 'password') ? ("auth-alert") : ""}
                           onChange={(e) => passwordChangeHandler(e)}/>
                </div>
                <div className="auth-field">
                    <input name="terms" type="checkbox" className="checkbox" checked={terms}
                           onChange={termsChangeHandler}/> I agree to the <a
                    href="https://catalytics.io/resources/terms-of-use.html" target="_blank">Catalytics Terms of Use</a>

                </div>
                {(!terms && showAlertTerms) ? (<div className="accept-terms-alert">Please accept the Catalytics Terms of Use</div>) : ''}
                <div className="button-container">
                    {(loading) ? (<div className="loader">Loading...</div>) : (
                        <button onClick={() => trySignUp()}>Sign Up</button>)}
                </div>
                <div className="create-account-container">
                    <p>Have an account? <a href="#!" onClick={() => changeAuthView('login')}>Sign in</a></p>
                </div>
            </div>);
        case 'confirm-signup':
            return (<div className="auth-container confirm-signup-container">
                <h3>Please enter the code we sent to your email</h3>
                {(authErrorAlert !== '') ? (<div className="auth-error-alert">{authErrorAlert}</div>) : ''}
                <div className="auth-field">
                    <label>Code</label>
                    <input type="text" name="validation-code"
                           className={(fieldAlert === 'validationCode') ? ("auth-alert") : ""}
                           onChange={(e) => validationCodeChangeHandler(e)}/>
                </div>
                <div className="button-container">
                    {(loading) ? (<div className="loader">Loading...</div>) : (
                        <button onClick={() => tryConfirmSignUp()}>Continue</button>)}
                </div>
            </div>);
        case 'reset':
            return (<div className="auth-container confirm-signup-container">
                <h3>Please enter the code we sent to your email and your new password</h3>
                {(authErrorAlert !== '') ? (<div className="auth-error-alert">{authErrorAlert}</div>) : ''}
                <div className="auth-field">
                    <label>Code</label>
                    <input type="text" name="reset-code" value={validationCode}
                           className={(fieldAlert === 'validationCode') ? ("auth-alert") : ""}
                           onChange={(e) => validationCodeChangeHandler(e)}/>
                </div>
                <div className="auth-field">
                    <label>New Password</label>
                    <input type="password" name="new-password"
                           className={(fieldAlert === 'password') ? ("auth-alert") : ""}
                           onChange={(e) => passwordChangeHandler(e)}/>
                </div>
                <div className="button-container">
                    {(loading) ? (<div className="loader">Loading...</div>) : (
                        <button onClick={() => tryReset()}>Continue</button>)}
                </div>
            </div>);
    }

}

export default AuthComponents